import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button, Input, Progress, Alert } from 'reactstrap'
import Select from "react-select"
import { AllGroups } from "./Queries"
import { UpdateGroupObjectPermissionMutation } from "./Mutation"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

class UpdateObjectPermissionModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: {},
            loading: false,
        }
    }

    updateObjectPermission = () => {

        if (this.state.input.id) {
            this.setState({ loading: true })
            let input = this.state.input
            this.props.apolloClient.mutate({
                mutation: UpdateGroupObjectPermissionMutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateGroupObjectPermission && result.data.updateGroupObjectPermission.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, error: null })
                }
                else {
                    let error = "An error occurred, could not complete request."
                    if (result && result.data && result.data.updateGroupObjectPermission && result.data.updateGroupObjectPermission.errors[0] && result.data.updateGroupObjectPermission.errors[0].messages) {
                        error = result.data.updateGroupObjectPermission.errors[0].messages.toString()
                    }
                    this.setState({ error: error })
                }
                this.setState({ loading: false })
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin: " + err })
            })
        } else {
        }
    }

    updateInput = (e) => {
        if (this.props.groupObjectPermissionId) {
            let name = e.target.name
            let value = e.target.value
            if (e.target.type === "number") {
                if (isNaN(parseFloat(value))) {
                    value = 0
                } else {
                    value = parseFloat(value)
                }
            }
            let input = this.state.input
            input["id"] = this.props.groupObjectPermissionId
            input[name] = value
            this.setState({ input: input })
        }
    }

    render() {
        return (
            <div>
                <Popover placement={this.props.placement ? this.props.placement : "bottom"} isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader onClick={this.props.handleClose}>{this.props.title} <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody>
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        <div className='pb-2'>
                            {this.props.name === "groups" ?
                                this.props.allGroups && this.props.allGroups.edges && this.props.allGroups.edges.length > 0 ?
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        defaultValue={this.props.allGroups && this.props.allGroups.edges && this.props.allGroups.edges.length > 0 && this.props.allGroups.edges.filter(group => this.props.value.includes(group.node.id)).map(group => ({ value: group.node.id, label: group.node.name }))}
                                        options={this.props.allGroups && this.props.allGroups.edges && this.props.allGroups.edges.length > 0 && this.props.allGroups.edges.map(group => ({ value: group.node.id, label: group.node.name }))}
                                        onChange={(item) => this.setState({ input: { ...this.state.input, groups: item.map(item => item.value), id: this.props.groupObjectPermissionId } })}
                                        isMulti
                                        placeholder={"Add New Groups"}
                                    />
                                    :
                                    <Alert color="info">No existing groups found!</Alert>
                                :
                                <Input autoComplete={"off"} type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200}
                                    name={this.props.name} id={this.props.name} defaultValue={this.props.value}
                                    placeholder={this.props.title} onChange={this.updateInput}
                                />
                            }
                            {this.state.loading ? <Progress animated color="info" value={100} /> :
                                <Button className="float-right submit-button  my-2 py-1" type="button" size="sm" onClick={this.updateObjectPermission} disabled={this.state.loading || this.props.loading}>Submit</Button>
                            }
                        </div>
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllGroups, { props:({ data: { allGroups } }) => ({ allGroups}) })
(withApolloClient(UpdateObjectPermissionModal));
