import React, { Component } from 'react'
import gql from 'graphql-tag'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Link } from 'react-router-dom'
import '../CarDetail.css'
import { Badge, Button, Col, Row, Label, Table, UncontrolledTooltip, Nav, NavItem, NavLink } from 'reactstrap'
import moment from 'moment-timezone'
import { EnumQuery } from '../../../Functions/querys'
import DriverNotes from '../../../ApplicationsNew/DriverNotes'
import UpdateReturnInfo from '../../CarListNew/UpdateReturnInfo'
import ScheduleReturnForm from '../../../Material/ScheduleReturnForm'
import CancelReturn from '../../../Material/CancelReturn'
import NewAgreementPrice from '../../../Material/NewAgreementPrice'
import Loader from "../../../Material/Loader"
import toTitleCase from "../../../Functions/toTitleCase"
import renderEnumToInt from "../../../Functions/renderEnumToInt"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
import '../../../Drivers/Driver/Detail/DetailsNew/DriverDetailNew.css'
import DocumentModalNew from "../../../Material/DocumentModal/DocumentModalNew"
import { cleanDocumentUrl } from "../../../Material/DocumentModal/DocumentsListNew"
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'
import { BILLING_DAYS } from "../../../Constants"

const AgreementDetailQuery = gql`query AgreementDetailQuery($pk: Int, $id:ID) {
    rental(pk:$pk,id: $id){
        id
        pk
        weeklyCharge
        startDate
        stage
        baseCharge
        insuranceUpcharge
        discount
        agreementType
        endDate
        notes
        branch{
            id
            name
        }
        representative{
            id
            username
            firstName
            lastName
        }
        splitAgreement{
            id
            driver{
                id
                name
            }
        }
        currentReturn{
            id
            pk
            stage
            scheduledDate
            closedDate
            dateAdded
            returnType
            returnCategory
            returnReason
            actualReturnReason
            returnStage
            driverPresent
            nextPickupDate
            gas
            isScheduled
            mileage
            damage
            notes
            driverNotes
            representative{
                id
                username
                firstName
                lastName
            }
            returnCondition{
                id
                gas
                mileage
                headlightsWorking
                carpicturesSet{
                    edges{
                        node{
                            id
                            pictureUrl
                            pictureType
                            description
                        }
                    }
                }
            }
        }
        upcomingRentalTask {
            amountToCharge
            scheduleTime
        }
        history{
            user
            date
            comment
        }
        carreturnSet{
            edges {
                node {
                    id
                    autoDepositRefund
                    dateAdded
                    returnType
                    returnCategory
                    returnReason
                    scheduledDate
                    stage
                    representative{
                        id
                        username
                        firstName
                        lastName
                    }
                }
            }
        }
        pickupCondition {
            gas
            mileage
            headlightsWorking
            dateAdded
            formData
            carpicturesSet{
                edges{
                    node{
                        id
                        dateAdded
                        pictureUrl
                        pictureType
                        description
                    }
                }
            }
        }
        transactionSet(orderBy:"-id"){
            edges{
                node{
                    id
                    pk
                    amount
                    eventDatetime
                    notes
                    balance
                    dateAdded
                    status
                    updateFor {
                        id
                        pk
                    }
                    chargeType{
                        name
                    }
                    groupType{
                        name
                    }
                    createdBy{
                        username
                        firstName
                        lastName
                    }
                }
            }
        }
        driver {
            id
            name
            tlcLicense
            dmvLicense
            phone
            email
            paymentMethod{
                id
                name
            }
            chargeDayofweek
            streetAddress
            state
            stage
            city
            zipCode
            chargeCcsurcharge
            deposit
            branch{
                id
                name
            }
        }
        agreementpriceSet {
            edges{
                node{
                    price
                    interval
                    intervalUnit
                    currency { code symbol }
                    id
                    isActive
                    startDate
                    createdBy {
                        id
                        username
                        firstName
                        lastName
                    }
                    updatedBy {
                        id
                        username
                        firstName
                        lastName
                    }
                }
            }
        }
    } 
}`

const historyTable = [
    { id: "date", name: "Date" },
    { id: "detail", name: "Details" },
    { id: "createdBy", name: "Created By" },
]
const pricingTable = [
    { id: "price", name: "Price" },
    { id: "startDate", name: "Start Date" },
    { id: "createdBy", name: "Created By" },
]
const transactionSets = [
    { id: "id", name: "ID" },
    { id: "date_added", name: "Date Added" },
    { id: "charge_type", name: "Charge Type" },
    { id: "amount", name: "Amount($)" },
    { id: "event_datetime", name: "Event Date" },
    { id: "createdBy", name: "Created By" },
]
class RentalDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            rental: null,
            agreementDetailSubsection: "Rental Info",
            returnDetailSubsection: "Active Return",
            transactionDetailSubsection: "Transactions History",
            driverDetailSubsection: "Driver Detail",
            images: null,
            transactionId: null,
        }
    }

    getAgreementState = (state) => {
        if (!this.props.agreementStates) {
            return "------"
        }
        let sobj = this.props.agreementStates.states.filter(s => s.name === state)
        return sobj.length ? sobj[0].description : "------"
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    toggleSectionNav = (sectionName, value) => {
        this.setState({ [sectionName]: value })
    }
    getCarReturnState = (state) => {
        if (!this.props.carReturnStates) {
            return "------"
        }
        let sobj = this.props.carReturnStates.states.filter(s => s.name === state)
        return sobj.length ? sobj[0].description : "------"
    }
    viewImages(picturesObject) {
        let tmpArr = []
        if (picturesObject && picturesObject.carpicturesSet && picturesObject.carpicturesSet.edges && picturesObject.carpicturesSet.edges.length > 0) {
            const toBeAdded = picturesObject.carpicturesSet.edges.map((pic, i) => ({ id: pic.node.id, documentUrl: cleanDocumentUrl(pic.node.pictureUrl), description: pic.node.description, dateAdded: pic.node.dateAdded, name: pic.node.pictureType }))
            tmpArr = [...tmpArr, ...toBeAdded]
        }
        this.setState({ returnPictures: tmpArr, selectedImage: tmpArr[0] })
        this.toggleModal("viewImages")
    }
    next = () => {
        const returnPictures = this.state.returnPictures
        const currentIndex = returnPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === returnPictures.length - 1) return
        this.setState({ selectedImage: returnPictures[currentIndex + 1] })
    }

    prev = () => {
        const returnPictures = this.state.returnPictures
        const currentIndex = returnPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === 0) return
        this.setState({ selectedImage: returnPictures[currentIndex - 1] })
    }
    openTransaction = (transactionId) => {
        this.setState({ openModal: "TransactionInfo", transactionId: transactionId })
    }
    render() {
        return (
            <>
                <Col className="zero-padding">
                    {this.props.loading ? <Loader /> : <>
                        {this.state.openModal === "NewAgreementPrice" && <NewAgreementPrice handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchAgreementsQuery} rental={this.props.rental} open={this.state.openModal === "NewAgreementPrice"} />}
                        {this.state.openModal === "viewImages" &&
                            <DocumentModalNew
                                document={this.state.selectedImage}
                                handleClose={() => this.setState({ selectedImage: null, openModal: "" })}
                                next={this.next}
                                prev={this.prev}
                                objectType={"car"}
                                isPicture={true}
                                currentUser={this.props.currentUser}
                            />
                        }
                        {this.props.rental && <div className="agreement-detail">
                            <Row>
                                <Col>
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "Driver Detail" ? "active" : "inactive"}>
                                            <NavLink className={this.state.driverDetailSubsection === "Driver Detail" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("driverDetailSubsection", "Driver Detail")}>
                                                DRIVER DETAILS
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    {this.state.driverDetailSubsection === "Driver Detail" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                <Col xs={3}>
                                                    <Label>Name</Label>
                                                    <p>{this.props.rental && this.props.rental.driver ? this.props.rental.driver.name : "---"} <Link color="primary" to={'/driver/' + this.props.rental.driver.id} target="_blank"><i className={"fa fa-external-link"}></i></Link></p>
                                                </Col>
                                                <Col xs={3}>
                                                    <Label>Phone Number</Label>
                                                    <p>{this.props.rental && this.props.rental.driver && this.props.rental.driver.phone ? this.props.rental.driver.phone : "---"}</p>
                                                </Col>
                                                <Col xs={3}>
                                                    <Label>Email</Label>
                                                    <p className="email">{this.props.rental && this.props.rental.driver && this.props.rental.driver.email ? this.props.rental.driver.email : "---"}</p>
                                                </Col>

                                                <Col xs={3}>
                                                    <Label>DMV</Label>
                                                    <p>{this.props.rental && this.props.rental.driver && this.props.rental.driver.dmvLicense ? this.props.rental.driver.dmvLicense : "---"}</p>
                                                </Col>
                                                <Col xs={3}>
                                                    <Label>TLC</Label>
                                                    <p>{this.props.rental && this.props.rental.driver && this.props.rental.driver.tlcLicense ? this.props.rental.driver.tlcLicense : "---"}</p>
                                                </Col>
                                                <Col xs={3}>
                                                    <Label>Branch</Label>
                                                    <p>{this.props.rental && this.props.rental.driver && this.props.rental.driver.branch ? this.props.rental.driver.branch.name : "---"}</p>
                                                </Col>
                                                <Col xs={3} id="paymentMethodEdit">
                                                    <Label><span onClick={() => this.toggleModal("paymentMethodEdit")}>Payment Method</span></Label>
                                                    <p>{this.props.rental.driver && this.props.rental.driver.paymentMethod && this.props.rental.driver.paymentMethod.name}</p>
                                                </Col>
                                                <Col xs={3} id="chargeDayofweekEdit">
                                                    <Label><span onClick={() => this.toggleModal("chargeDayofweekEdit")}>Invoice Day</span></Label>
                                                    <p>
                                                        {this.props.rental.driver && BILLING_DAYS.filter((day, i) => day.value === this.props.rental.driver.chargeDayofweek).map((day, i) => <span key={i}>{day.label}</span>)}
                                                    </p>
                                                </Col>
                                                <Col xs={3} id="chargeDayofweekEdit">
                                                    <Label>Billing Cycle</Label>
                                                    <p>
                                                        <span>
                                                            {this.props.rental.driver &&
                                                                this.props.rental.driver.currentAgreement &&
                                                                this.props.rental.driver.currentAgreement.agreementpriceSet &&
                                                                this.props.rental.driver.currentAgreement.agreementpriceSet.edges &&
                                                                this.props.rental.driver.currentAgreement.agreementpriceSet.edges.length > 0 &&
                                                                this.props.rental.driver.currentAgreement.agreementpriceSet.edges[0].node &&
                                                                this.props.rental.driver.currentAgreement.agreementpriceSet.edges[0].node.intervalUnit &&
                                                                this.props.rental.driver.currentAgreement.agreementpriceSet.edges[0].node.intervalUnit.toLowerCase() === 'week' ?
                                                                BILLING_DAYS.filter((day, i) =>
                                                                    day.value === this.props.rental.driver.chargeDayofweek).map((day, i) =>
                                                                        day.label + " - " + (day.value === 0 ?
                                                                            "Sunday" :
                                                                            BILLING_DAYS.filter(firstDay => firstDay.value + 1 === day.value).map(firstDay => firstDay.label)))
                                                                : "--"
                                                            }
                                                        </span>
                                                    </p>
                                                </Col>
                                                <Col xs={3}>
                                                    <Label>CC surcharge</Label>
                                                    <p>
                                                        <span style={{ cursor: "pointer" }}>
                                                            <i className={this.props.rental && this.props.rental.driver && this.props.rental.driver.chargeCcsurcharge === true ? "fa fa-toggle-on switch" : "fa fa-toggle-off switch"} aria-hidden="true"></i>
                                                        </span>
                                                    </p>
                                                </Col>
                                                <Col xs={3}>
                                                    <Label>
                                                        Deposit
                                                    </Label>
                                                    <p>
                                                        {this.props.rental && this.props.rental.driver && this.props.rental.driver.deposit ? this.props.rental.driver.deposit : " ---"}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "Rental Info" ? "active" : "inactive"}>
                                            <NavLink className={this.state.agreementDetailSubsection === "Rental Info" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("agreementDetailSubsection", "Rental Info")}>
                                                RENTAL INFO
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={this.state.tab === "Rental Pricing" ? "active" : "inactive"}>
                                            <NavLink className={this.state.agreementDetailSubsection === "Rental Pricing" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("agreementDetailSubsection", "Rental Pricing")}>
                                                RENTAL PRICING
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={this.state.tab === "Rental History" ? "active" : "inactive"}>
                                            <NavLink className={this.state.agreementDetailSubsection === "Rental History" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("agreementDetailSubsection", "Rental History")}>
                                                RENTAL HISTORY
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    {this.state.agreementDetailSubsection === "Rental Info" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                <Col xs={4}>
                                                    <Label>Rental Stage</Label>
                                                    <p>
                                                        <Badge style={{ backgroundColor: this.props.rental.stage === "A__1_CANCELED" ? "red" : this.props.rental.stage === "A_10_ACTIVE" ? "green" : "orange" }}>
                                                            {this.getAgreementState(this.props.rental.stage)}
                                                        </Badge>
                                                    </p>
                                                    {this.props.rental.splitAgreement && this.props.rental.splitAgreement.id && <Link color="primary" to={'/driver/' + this.props.rental.splitAgreement.driver.id} target="_blank"><Badge color="warning"><small>SPLIT: {this.props.rental.splitAgreement.driver.name} <i className={"fa fa-external-link"}></i></small></Badge></Link>}
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>UPCOMING CHARGE</Label>
                                                    <p>
                                                        {this.props.rental.upcomingRentalTask && this.props.rental.upcomingRentalTask.amountToCharge ? ("$" + this.props.rental.upcomingRentalTask.amountToCharge + " on ") : "--"}&nbsp;{this.props.rental.upcomingRentalTask && this.props.rental.upcomingRentalTask.scheduleTime ? (<DatetimeRenderer datetime={this.props.rental.upcomingRentalTask.scheduleTime} />) : "--"}
                                                    </p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>Start Date</Label>
                                                    <p>{this.props.rental &&
                                                        this.props.rental.startDate ? moment(this.props.rental.startDate).tz("America/New_York").format("lll") : "--"}
                                                    </p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>End Date</Label>
                                                    <p>{this.props.rental &&
                                                        this.props.rental.endDate ? moment(this.props.rental.endDate).tz("America/New_York").format("lll") : "--"}
                                                    </p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>Agreement Type</Label>
                                                    <p>{this.props.rental && this.props.rental.agreementType ? this.props.rental.agreementType : "--"}</p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>Gas Level</Label>
                                                    <p>{this.props.rental.pickupCondition && this.props.rental.pickupCondition.gas ? this.props.rental.pickupCondition.gas : "--"}</p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>Mileage</Label>
                                                    <p>{this.props.rental.pickupCondition && this.props.rental.pickupCondition.gas ? this.props.rental.pickupCondition.mileage : "--"}</p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>Pickup Pictures</Label>
                                                    <p>
                                                        {
                                                            this.props.rental && this.props.rental.pickupCondition && this.props.rental.pickupCondition.carpicturesSet &&
                                                                this.props.rental.pickupCondition.carpicturesSet.edges && this.props.rental.pickupCondition.carpicturesSet.edges.length > 0 ?
                                                                <Button size="sm" className="ghost" onClick={() => this.viewImages(this.props.rental.pickupCondition)}>SHOW/HIDE</Button>
                                                                : "---"
                                                        }
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>}
                                    {this.state.agreementDetailSubsection === "Rental Pricing" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                {<NewAgreementPrice handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchAgreementsQuery} rental={this.props.rental} open={this.state.openModal === "NewAgreementPrice"} />}
                                                <Col className="d-flex justify-content-end">
                                                    <Button size="sm" onClick={() => this.toggleModal("NewAgreementPrice")} className="primary">Add/Change Plan</Button>
                                                    <p></p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {this.props.rental && this.props.rental.agreementpriceSet && this.props.rental.agreementpriceSet.edges && this.props.rental.agreementpriceSet.edges.length > 0 && pricingTable.map(tableHeader =>
                                                    <Col key={tableHeader.id}>
                                                        <Label>{tableHeader.name}</Label>
                                                    </Col>
                                                )}
                                            </Row>
                                            {this.props.rental && this.props.rental.agreementpriceSet && this.props.rental.agreementpriceSet.edges && this.props.rental.agreementpriceSet.edges.length > 0 ? this.props.rental.agreementpriceSet.edges.map(plan =>
                                                <Row className={plan.node.isActive ? "activePrice" : ""} style={{ backgroundColor: plan.node.isActive ? "green" : "", color: plan.node.isActive ? "white" : "", padding: "5px", borderRadius: "5px" }}>
                                                    <Col>${plan.node.price} per {plan.node.interval > 1 ? plan.node.interval : ""}{plan.node.intervalUnit}</Col>
                                                    <Col>{plan.node.startDate ? moment(plan.node.startDate).tz("America/New_York").format("ddd, MMM Do") : "--"}</Col>
                                                    <Col>{plan.node.isActive ? "ACTIVE" : "IN-ACTIVE"}</Col>
                                                    <Col>{this.props.rental.representative ? this.props.rental.representative.firstName ? this.props.rental.representative.firstName + " " + this.props.rental.representative.lastName : this.props.rental.representative.username : "--"}</Col>
                                                </Row>) : <Row><Col xs={12}><p>No Rental Price Found</p></Col></Row>
                                            }
                                        </div>
                                    }
                                    {this.state.agreementDetailSubsection === "Rental History" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                {historyTable.map(tableHeader =>
                                                    <Col key={tableHeader.id} xs={4}><Label >{tableHeader.name}</Label></Col>
                                                )}
                                            </Row>
                                            {this.props.rental.history.length > 0 ? this.props.rental.history.map(history =>
                                                <Row>
                                                    <Col><p>{history.date ? moment(history.date).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"}</p></Col>
                                                    <Col><p>{history.comment ? history.comment : "--"}</p></Col>
                                                    <Col><p>{history.user ? history.user : "--"}</p></Col>
                                                </Row>) :
                                                <Row>
                                                    <Col>
                                                        <p>No Rental History</p>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    {/* <Row>
                                        <Col>
                                            <h4>
                                                Active Return
                                            </h4>
                                        </Col>
                                        {this.props.rental.currentReturn && <Col className="text-right">
                                            <Badge color="secondary">{this.getCarReturnState(this.props.rental.currentReturn.stage)}</Badge>
                                        </Col>}
                                    </Row> */}
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "Active Return" ? "active" : "inactive"}>
                                            <NavLink className={this.state.returnDetailSubsection === "Active Return" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("returnDetailSubsection", "Active Return")}>
                                                ACTIVE RETURN
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={this.state.tab === "Return History" ? "active" : "inactive"}>
                                            <NavLink className={this.state.returnDetailSubsection === "Return History" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("returnDetailSubsection", "Return History")}>
                                                RETURN HISTORY
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    {this.state.returnDetailSubsection === "Active Return" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            {this.props.rental.currentReturn ?
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <Label>
                                                                <span id={"returnIsScheduledAt"}>Return Scheduled For</span>
                                                                <UncontrolledTooltip
                                                                    placement="right"
                                                                    target={"returnIsScheduledAt"}
                                                                >
                                                                    {"Created On: " + (this.props.rental.currentReturn.dateAdded ?
                                                                        moment(this.props.rental.currentReturn.dateAdded).tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL).replace("T", " ") :
                                                                        "")
                                                                    }
                                                                </UncontrolledTooltip>
                                                            </Label>
                                                            <p>
                                                                {this.props.rental.currentReturn.scheduledDate ?
                                                                    <>
                                                                        {moment(this.props.rental.currentReturn.scheduledDate).tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL).replace("T", " ")}
                                                                        <Badge
                                                                            className="ml-3"
                                                                            id={"returnIsScheduled"}
                                                                            color={this.props.rental.currentReturn.isScheduled ? "success" : "danger"}
                                                                            style={{ height: "10px", padding: "4px" }}>&nbsp;
                                                                        </Badge>
                                                                        <UncontrolledTooltip
                                                                            placement="right"
                                                                            target={"returnIsScheduled"}
                                                                        >
                                                                            {this.props.rental.currentReturn.isScheduled ? "Scheduled Return" : "Un-Scheduled Return"}
                                                                        </UncontrolledTooltip>
                                                                    </> :
                                                                    "--"
                                                                }
                                                            </p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Return Category</Label>
                                                            <p>{this.props.rental.currentReturn.returnCategory}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Reason</Label>
                                                            <p>{this.props.rental.currentReturn.returnReason}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Created By</Label>
                                                            <p>{
                                                                this.props.rental.currentReturn.representative ?
                                                                    this.props.rental.currentReturn.representative.firstName ?
                                                                        this.props.rental.currentReturn.representative.firstName + " " + this.props.rental.currentReturn.representative.lastName :
                                                                        this.props.rental.currentReturn.representative.username :
                                                                    "--"
                                                            }
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <Label>Return Stage</Label>
                                                            <p><Badge color="secondary">{this.getCarReturnState(this.props.rental.currentReturn.stage)}</Badge></p>
                                                        </Col>
                                                        <Col xs={3} >
                                                            <Label>Gas</Label>
                                                            <p>{this.props.rental.currentReturn.gas ? renderEnumToInt(this.props.rental.currentReturn.gas) : "--"}</p>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Label>Mileage</Label>
                                                            <p>{this.props.rental.currentReturn.mileage ? this.props.rental.currentReturn.mileage : "--"}</p>
                                                        </Col>
                                                        {this.props.detailPageType === "driver" && <Col>
                                                            {this.props.rental.currentReturn && this.props.rental.currentReturn.stage && this.getCarReturnState(this.props.rental.currentReturn.stage) !== "Returned" && <>
                                                                {this.state.openModal === "CancelReturn" && <CancelReturn handleClose={() => this.toggleModal("")} carReturnId={this.props.rental.currentReturn.id} open={this.state.openModal === "CancelReturn"} refetchQuery={() => { this.props.refetchAgreementsQuery() }} returnStages={this.props.carreturnStates} />}
                                                                <Label>Action</Label>
                                                            </>}
                                                            <p>
                                                                {this.state.openModal === "EditReturn" &&
                                                                    <ScheduleReturnForm refetchQuery={() => { this.props.refetchAgreementsQuery() }} handleClose={() => this.toggleModal("")} rental={this.props.rental} carReturn={this.props.rental.currentReturn} car={this.props.rental.car} open={this.state.openModal === "EditReturn"} editReturn={true} returnStages={this.props.carReturnStates} currentUser={this.props.currentUser ? this.props.currentUser : ""} />
                                                                }
                                                                {this.props.rental.currentReturn && this.props.rental.currentReturn.stage && this.getCarReturnState(this.props.rental.currentReturn.stage) && this.getCarReturnState(this.props.rental.currentReturn.stage).includes("Awaiting Closeout") &&
                                                                    <Button size="sm" className="primary" onClick={() => this.toggleModal("CloseReturn")}>CLOSE&nbsp;RETURN</Button>
                                                                }
                                                                {this.props.rental.currentReturn && this.props.rental.currentReturn.stage && this.getCarReturnState(this.props.rental.currentReturn.stage) !== "Cancelled" && this.getCarReturnState(this.props.rental.currentReturn.stage) !== "Returned" &&
                                                                    <><Button size="sm" className="ghost" onClick={() => this.toggleModal("CancelReturn")}>CANCEL</Button>&nbsp;</>
                                                                }
                                                                {this.props.rental.currentReturn && this.props.rental.currentReturn.stage && (this.getCarReturnState(this.props.rental.currentReturn.stage) !== "Cancelled" && !this.getCarReturnState(this.props.rental.currentReturn.stage).includes("Awaiting Closeout") && this.getCarReturnState(this.props.rental.currentReturn.stage) !== "Returned") &&
                                                                    <><Button size="sm" className="ghost" onClick={() => this.toggleModal("EditReturn")}>EDIT</Button>&nbsp;</>
                                                                }
                                                            </p>
                                                        </Col>}
                                                    </Row>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <Label id={"returnNotes"}>
                                                                Return Notes&nbsp;&nbsp;
                                                                <i onClick={() => this.toggleModal("returnNotesEdit")} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                                                <i onClick={() => this.toggleModal("returnNotesHistory")} className={"fa fa-history"}></i><br />
                                                            </Label>
                                                            {this.state.openModal === "returnNotesEdit" &&
                                                                <UpdateReturnInfo
                                                                    carReturn={this.props.rental.currentReturn}
                                                                    title="Edit Return Notes" name="notes"
                                                                    value={this.props.rental.currentReturn.notes}
                                                                    target={"returnNotes"}
                                                                    id="returnNotes"
                                                                    type="text"
                                                                    open={this.state.openModal === "returnNotesEdit"}
                                                                    handleClose={() => this.toggleModal("")}
                                                                    refetchQuery={this.props.refetchAgreementsQuery}
                                                                    placement={"left"}
                                                                />
                                                            }
                                                            {this.state.openModal === "returnNotesHistory" &&
                                                                <DriverNotes
                                                                    driver={this.props.rental.driver}
                                                                    noteType="RETURN"
                                                                    title="Return Notes"
                                                                    objectId={this.props.rental.currentReturn.id}
                                                                    target={"returnNotes"}
                                                                    open={this.state.openModal === "returnNotesHistory"}
                                                                    handleClose={() => this.toggleModal("")} placement={"left"}
                                                                />
                                                            }
                                                            <p>
                                                                {this.props.rental.currentReturn.notes.split('\n').map((line, i) =>
                                                                    <span key={i}>{line}</span>
                                                                )}
                                                            </p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Return Pictures</Label>
                                                            <p>
                                                                {this.props.rental && this.props.rental.currentReturn && this.props.rental.currentReturn.returnCondition && this.props.rental.currentReturn.returnCondition.carpicturesSet &&
                                                                    this.props.rental.currentReturn.returnCondition.carpicturesSet.edges && this.props.rental.currentReturn.returnCondition.carpicturesSet.edges.length > 0 ?
                                                                    <Button size="sm" className="ghost" onClick={() => this.viewImages(this.props.rental.currentReturn.returnCondition)} >SHOW/HIDE</Button>
                                                                    : <span>--</span>
                                                                }
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </div> :
                                                <Row>
                                                    <Col xs={12}>
                                                        <p className="m-0">No Return Scheduled!</p>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    }
                                    {this.state.returnDetailSubsection === "Return History" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                <Col><Label>Return Category</Label></Col>
                                                <Col><Label>Driver Cancel Reason</Label></Col>
                                                <Col><Label>Cancelled Date</Label></Col>
                                                <Col><Label>Scheduled Date</Label></Col>
                                                <Col><Label>Rep</Label></Col>
                                            </Row>
                                            {
                                                this.props.rental.carreturnSet &&
                                                    this.props.rental.carreturnSet.edges &&
                                                    this.props.rental.carreturnSet.edges.length > 0 &&
                                                    this.props.rental.carreturnSet.edges.length > 0 ?
                                                    this.props.rental.carreturnSet.edges.reverse().map(item =>
                                                        <Row key={item.node.id}>
                                                            <Col>
                                                                <p>{toTitleCase(item.node.returnCategory)}</p>
                                                            </Col>
                                                            <Col>
                                                                <p>{item.node.returnReason}</p>
                                                            </Col>
                                                            <Col>
                                                                <p>{item.node.cancelledDate ? moment(item.node.cancelledDate).tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL).replace("T", " ") : "--"}</p>
                                                            </Col>
                                                            <Col>
                                                                <p>{item.node.scheduledDate ? moment(item.node.scheduledDate).tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL).replace("T", " ") : "--"}</p>
                                                            </Col>
                                                            <Col>
                                                                <p>{item.node.representative ? item.node.representative.firstName ? item.node.representative.firstName + " " + item.node.representative.lastName : item.node.representative.username : "--"}</p>
                                                            </Col>
                                                        </Row>
                                                    ) :
                                                    <Row><Col><p> No returns history found! </p></Col> </Row>}
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <br />
                            <Row >
                                <Col>
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "Transactions History" ? "active" : "inactive"}>
                                            <NavLink className={this.state.transactionDetailSubsection === "Transactions History" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("transactionDetailSubsection", "Transactions History")}>
                                                TRANSACTIONS HISTORY
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <div className="info-container" style={{ borderTopLeftRadius: "0px", maxHeight: "300px", overflow: "auto" }}>
                                        {this.state.transactionDetailSubsection === "Transactions History" &&
                                            <>
                                                <Row>
                                                    {transactionSets.map(tableHeader => {
                                                        return (
                                                            tableHeader.sortable ?
                                                                <Col>
                                                                    <Label key={tableHeader.id} onClick={() => this.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}</Label>
                                                                </Col> :
                                                                <Col>
                                                                    <Label key={tableHeader.id}>{tableHeader.name}</Label>
                                                                </Col>
                                                        )
                                                    })
                                                    }
                                                </Row>
                                                {
                                                    this.props.rental &&
                                                        this.props.rental.transactionSet &&
                                                        this.props.rental.transactionSet.edges &&
                                                        this.props.rental.transactionSet.edges.length > 0 ?
                                                        this.props.rental.transactionSet.edges.map((transaction, i) =>
                                                            <Row key={i + 1}>
                                                                <Col>
                                                                    <p>
                                                                        {transaction.node.pk}
                                                                        {transaction.node.updateFor && <><br /><small style={{ opacity: 0.9, fontSize: "0.9rem", textDecoration: "line-through" }}>{transaction.node.updateFor.pk}</small></>}
                                                                    </p>
                                                                </Col>
                                                                <Col><p>{transaction.node.dateAdded ? moment(transaction.node.dateAdded).format('MM-DD-YYYY') : "--"}</p></Col>
                                                                <Col className={`shortText ${transaction.node.amount > 0 ? "danger" : "success"}`}>
                                                                    <p>
                                                                        {transaction.node.chargeType ? transaction.node.chargeType.name : '--'} <small>{transaction.node.groupType ? " (" + transaction.node.groupType.name + ")" : ''}</small>
                                                                        {transaction.node.notes &&
                                                                            <>
                                                                                <UncontrolledTooltip placement="top" target={"notes" + i}>{transaction.node.notes}</UncontrolledTooltip>
                                                                                <br /><small style={{ opacity: 0.9, fontSize: "0.9rem", cursor: "pointer" }} id={"notes" + i}>{transaction.node.notes}</small>
                                                                            </>
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col className={transaction.node.amount > 0 ? "danger" : "success"}>
                                                                    <p>
                                                                        {transaction.node.amount}
                                                                        {transaction.node.status === "0" && <Badge style={{ "fontSize": "xx-small" }}>PENDING</Badge>}
                                                                    </p>
                                                                </Col>
                                                                <Col><p>{transaction.node.eventDatetime ? moment(transaction.node.eventDatetime).format('MM-DD-YYYY') : "--"}</p></Col>
                                                                <Col><p>{transaction.node.createdBy ? transaction.node.createdBy.firstName ? transaction.node.createdBy.firstName + " " + transaction.node.createdBy.lastName : transaction.node.createdBy.username : "System"}</p></Col>
                                                            </Row>) :
                                                        this.props.loading ?
                                                            <Row>
                                                                <Col>Loading Transactions List...</Col>
                                                            </Row> :
                                                            <Row>
                                                                <Col>No Transaction Found</Col>
                                                            </Row>
                                                }
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        }
                    </>}
                </Col>
            </>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(EnumQuery, {
    props: ({ data: { agreementStates, carReturnStates } }) => ({
        agreementStates,
        carReturnStates,
    }),
})(
    graphql(AgreementDetailQuery, {
        options: ({ id }) => ({
            variables: { id },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        }),
        props: ({ data: { loading, rental, fetchMore, variables } }) => ({
            loading,
            rental,
            variables,
            refetchAgreementsQuery: () => {
                return fetchMore({
                    query: AgreementDetailQuery,
                    variables: { ...variables },
                    updateQuery: (previousResult, { fetchMoreResult }) => ({
                        rental: fetchMoreResult.rental,
                        __typename: previousResult.rental.__typename,
                    }),
                })
            },
        }),
    })(withApolloClient(RentalDetail))
)
