import React, { Component } from 'react'
import { Col, Row, Alert, Button, Input, FormGroup, Label, Progress } from 'reactstrap'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import gql from 'graphql-tag'
import { Elements, StripeProvider } from "react-stripe-elements"
// import { CardForm } from "../strip/stripe"
import { GetConfigurationSettings } from "../Functions/querys"
import "./CdwSignup.css"
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

const requestCardFromDriver = gql`mutation requestCardFromDriver($input:RequestCardFromDriverMutationInput!){
    requestCardFromDriver(input:$input){
      ok
      errors{
        field
        messages
      }
    }
}`

const StripeCards = gql`query StripeCards($id: ID!){
    cards: getStripeCards(id: $id){
        id
        cards {
            id
            expYear
            expMonth
            last4
            brand
        }
    } 
}`

const SubscriptionTypes = gql`query SubscriptionTypes($agreementTypeId: ID){
    allSubscriptionTypes(isActive:true, agreementTypeId: $agreementTypeId){
        edges{
          node{
            id
            amount
            name
            deductibleAmount
          }
        }
    }
}`

const createCardMutation = gql`mutation createCard($input:CreateCardMutationInput!){
    createCard(input:$input){
        ok
        card{
            id
        }
        errors{
            field
            messages
        }
    }
}`

const CreateSubscriptionMutation = gql`mutation createSubscription($input:CreateSubscriptionMutationInput!){
    createSubscription(input:$input){
        ok
        errors{
            messages
        }
    }
}`

class CdwSignup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cardRequest: false,
            cardRequestLoading: false,
            cardAdded: true,
            cardAddError: null,
            selectedCard: null,
            subscriptionPaymentMethod: "",
            subscriptionCreated: false,
            errors: null,
            subscriptionType: props.allSubscriptionTypes && props.allSubscriptionTypes.edges && props.allSubscriptionTypes.edges.length > 0 ? props.allSubscriptionTypes.edges[0].node.id : null
        }
    }
    // addCardLoader = (action) => {
    //     this.setState({
    //         loading: action ? "Adding card...please wait" : action
    //     })
    // };
    // addCard = (payload) => {
    //     if (payload) {
    //         let input = {
    //             driver: this.props.driverId,
    //             tokenId: payload,
    //             setAsDefault: false
    //         }
    //         this.props.client.mutate({
    //             mutation: createCardMutation,
    //             variables: { input }
    //         }).then((result) => {
    //             if (result && result.data && result.data.createCard && result.data.createCard.ok && result.data.createCard.card && result.data.createCard.card.id) {
    //                 this.setState({ cardAdded: true, selectedCard: result.data.createCard.card.id })
    //             } else {
    //                 let errorMessage = "Error Adding Card"
    //                 if (result.data.createCard.errors && result.data.createCard.errors[0] && result.data.createCard.errors[0].messages) {
    //                     errorMessage = result.data.createCard.errors[0].messages[0]
    //                 }
    //                 this.setState({ errors: errorMessage })
    //             }
    //             this.setState({ loading: false })
    //         })
    //     }
    // };
    createSubscription = () => {
        if (this.props.driverId && this.state.subscriptionType) {
            this.setState({ loading: "Creating subscription...please wait" })
            let input = {
                driverId: this.props.driverId,
                subscriptionTypeId: this.state.subscriptionType,
                paymentMethod: this.state.selectedCard,
            }
            this.props.apolloClient.mutate({
                mutation: CreateSubscriptionMutation,
                variables: { input }
            }).then((result) => {
                if (result && result.data && result.data.createSubscription && result.data.createSubscription.ok) {
                    this.setState({ subscriptionCreated: true })
                    if (this.props.subscriptionCreated) {
                        this.props.subscriptionCreated()
                    }
                    if (this.props.handleClose) {
                        this.props.handleClose()
                    }
                } else {
                    let errorMessage = "An error occured. Please try again or contact admin."
                    if (result && result.data && result.data.createSubscription && result.data.createSubscription.errors && result.data.createSubscription.errors[0] && result.data.createSubscription.errors[0].messages) {
                        errorMessage = result.data.createSubscription.errors[0].messages[0]
                    }
                    this.setState({ errors: errorMessage })
                }
                this.setState({ loading: false })
            })
        }
    };
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value })
        if (name === "subscriptionPaymentMethod") {
            if (value === "newCard" || !value) {
                this.setState({ selectedCard: "" })
            } else {
                this.setState({ selectedCard: value })
            }
        }
    };

    requestCardFromDriver = () => {
        this.setState({ cardRequestLoading: true })
        let input = {
            driverId: this.props.driverId
        }
        this.props.apolloClient.mutate({
            mutation: requestCardFromDriver,
            variables: { input }
        }).then((result) => {
            if (result && result.data && result.data.requestCardFromDriver && result.data.requestCardFromDriver.ok) {
                this.setState({ cardRequestLoading: false, cardRequest: true })
            } else {
                let errorMessage = "Request New Card Failed"
                if (result.data.requestCardFromDriver.errors && result.data.requestCardFromDriver.errors[0] && result.data.requestCardFromDriver.errors[0].messages) {
                    errorMessage = result.data.requestCardFromDriver.errors[0].messages
                }
                this.setState({ error: errorMessage, cardRequestLoading: false, cardRequest: false })
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.allSubscriptionTypes !== this.props.allSubscriptionTypes) {
            if (this.props.allSubscriptionTypes && this.props.allSubscriptionTypes.edges && this.props.allSubscriptionTypes.edges.length > 0) {
                this.setState({ subscriptionType: this.props.allSubscriptionTypes.edges[0].node.id })
            }
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            !this.state.subscriptionCreated ?
                this.props.stripePublicKey ? <StripeProvider apiKey={this.props.stripePublicKey}>
                    <Row className="stripeCards">
                        {this.state.errors &&
                            <Col xs={12}>
                                <Alert color="danger">{this.state.errors}</Alert>
                            </Col>
                        }
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="subscriptionType">Which subscription is the driver signing up for?</Label>
                                <Input type="select" name="subscriptionType" id="subscriptionType" onChange={this.updateInput}>
                                    {this.props.allSubscriptionTypes && this.props.allSubscriptionTypes.edges && this.props.allSubscriptionTypes.edges.length > 0 && this.props.allSubscriptionTypes.edges.map((subscriptionType, i) =>
                                        <option key={i} value={subscriptionType.node.id}>{subscriptionType.node.name} - Deductible: ${subscriptionType.node.deductibleAmount} - Payment: ${subscriptionType.node.amount}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="subscriptionPaymentMethod">How will driver pay for this subscription?</Label>
                                <Input type="select" name="subscriptionPaymentMethod" id="subscriptionPaymentMethod" onChange={this.updateInput}>
                                    <option value={null}>Use Default Card</option>
                                    {this.props.cards && this.props.cards.cards && this.props.cards.cards.length > 0 && this.props.cards.cards.map((card, i) =>
                                        <option key={i} value={card.id}>{card.brand + ": **" + card.last4 + ". Exp: " + card.expMonth + "/" + card.expYear}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col xs={12}>
                            <Row>
                                <Col className="d-flex justify-content-end">
                                    {this.state.loading ?
                                        <span>{this.state.loading === true ? "Loading...please wait..." : this.state.loading}</span> :
                                        <Button className="cdw-signup-modal-primary-btn"
                                            onClick={this.createSubscription}
                                            disabled={(this.state.loading
                                                || this.state.subscriptionCreated
                                                || !this.state.subscriptionType
                                            )}
                                        >{this.state.subscriptionCreated ? "Subscription added" : "Create Subscription"}</Button>
                                    }
                                    &nbsp;&nbsp;
                                    {this.state.cardRequestLoading ?
                                        <span>{this.state.cardRequestLoading ? "Loading...please wait..." : this.state.cardRequestLoading}</span> :
                                        <Button className="cdw-signup-modal-primary-btn" disabled={this.state.cardRequest} onClick={this.requestCardFromDriver}>{this.state.cardRequest ? "Link Triggred" : "Request Card"}</Button>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </StripeProvider> : "Loading..." :
                <Row className="stripeCards">
                    <Col xs={12}><br /><Alert color="success">Subscription created successfully!</Alert></Col>
                </Row>

        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(GetConfigurationSettings, {
    options: ({ driverId }) => ({
        variables: { name: "STRIPE_PUBLIC_KEY", driverId },
    }),
    props: ({ data: { getConfigurationSettings } }) => ({
        stripePublicKey: getConfigurationSettings,
    }),
})(
    graphql(StripeCards, {
        options: ({ driverId }) => ({
            variables: { id: driverId },
        }),
        props: ({ data: { loading, cards, variables } }) => ({
            loading,
            cards,
            variables,
        }),
    })(
        graphql(SubscriptionTypes, {
            options: ({ agreementTypeId }) => ({
                fetchPolicy: 'network-only',
                variables: { agreementTypeId },
            }),
            props: ({ data: { loading, allSubscriptionTypes } }) => ({
                loading,
                allSubscriptionTypes,
            }),
        })(
            withApolloClient(CdwSignup)
        )
    )
)
