import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from "reactstrap"
import { Route, Routes, Link } from 'react-router-dom'
import AutomatelyListHeader from "./AutomatelyListHeader"
import AutomationForm from "./AutomationForm"
import AutomationList from "./AutomationList"
import AutomationDetailContainer from "./AutomationDetailContainer"
import withApolloClient from '../withApolloClient' // Iagami - Code upgradation React 18

export default function AutomatelyContainer(props) {

    return (
        <div className="AutomatelyContainer">
            <Routes>
                <Route path="/new" element={<AutomationForm />} />
                <Route path="/:id" element={<AutomationDetailContainer client={props?.apolloClient} />} />
                <Route path="/" element={<AllAutomations  client={props?.apolloClient}/>} />
            </Routes>

        </div>

    )
}

const AllAutomations = (props) => {
    const [filterValues, setFilterValues] = useState(() => {
        // Retrieve filters from localStorage, if they exist
        const savedFilters = localStorage.getItem('AutomationContainerFilters')
        return savedFilters ? JSON.parse(savedFilters) : { searchTerm: "" }
    })
    const [selectedIds, setSelectedIds] = useState([])

    useEffect(() => {
        // Save filters to localStorage whenever they change
        localStorage.setItem('AutomationContainerFilters', JSON.stringify(filterValues))
    }, [filterValues])
    return (
        <Container fluid>
            <AutomatelyListHeader
                activeTab="all"
                filterValues={filterValues}
                setFilterValues={(res) => setFilterValues({ ...res })}
                selectedIds={selectedIds}
                resetSelectedIds={() => setSelectedIds([])}
                client={props?.apolloClient}
            />
            <Row>
                <Col>
                    <AutomationList {...filterValues} />
                </Col>
            </Row>
        </Container>
    )
}
