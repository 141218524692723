import gql from "graphql-tag"

const RepsQuery = gql`
     query AllRepsQuery($groups:[String], $orderBy:[String]){
        optionsList: allReps(isStaff:true, isActive:true, groups:$groups, orderBy:$orderBy){
        edges{
          node{
            id
            name
            username
            email
            isBilling
            isFrontOffice
            assignedCount
          }
        }
      }
    }
`

const TagsQuery = gql`query TagsQuery{
    optionsList: allTags(contentType: 13){
        edges{
            node{
                id
                name
                color
            }
        }
    }
}`

const AllCustomerLeadsQuery = gql`query AllCustomerLeadsQuery($cursor: String, $first: Int!, $assignedTo: [ID], $searchTerm: String, $orderBy: [String], $statuses: [String], $isArchived: Boolean, $driverTags: [String], $leadTypes: [String], $inOffice: Boolean,$loadMissingApplicationDocs: Boolean!,$loadMissingInsuranceDocs:Boolean!, $isBlocked: Boolean, $settingsKeyNames: [String]) {
    allCustomerLeads(first: $first, assignedTo: $assignedTo, orderBy: $orderBy, after: $cursor, searchTerm: $searchTerm, statuses: $statuses, isArchived: $isArchived, driverTags: $driverTags, leadTypes: $leadTypes,  inOffice: $inOffice, isBlocked: $isBlocked){
      edges {
        node {
          id
          pk
          dateAdded
          leadType
          status
          fullName
          notes
          email
          phone
          message
          source
          referredBy
          formUrl
          isArchived
          isBlocked
          metadata
          hasDuplicateEmail
          hasDuplicatePhone
          branch {
            id
            name
            branchSettings(key_Name_In: $settingsKeyNames) {
              edges {
                node {
                  id
                  value
                  key {
                    id 
                    name
                  }
                }
              }
            }
          }
          assignedRep {
            id
            username
            firstName
            lastName
          }
          customer{
            id
            name
            dmvLicense
            tlcLicense
            dob
            preferredLanguage
            inOffice
            palencaUserId
            missingApplicationDocuments @include(if: $loadMissingApplicationDocs) {
              id
              typeName
            }
            missingInsuranceDocuments @include(if: $loadMissingInsuranceDocs) {
              id
              typeName
            }
            tags {
              id
              name
            }
            palencaAccounts {
                  accountId
                  platform
                  lastSuccessfulConnection
                  status
                  identifier
                  createdAt
                }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        length
      }
    }
}`

export const AllPalencaAccountsQuery = gql`
query AllPalencaAccounts($palencaUserId: String){
    allPalencaAccounts(palencaUserId: $palencaUserId){
        accountId
        platform
        lastSuccessfulConnection
        status
        identifier
        createdAt
    }
}
`

const CustomerLeadQuery = gql`query CustomerLeadQuery($id: ID!) {
    customerLead(id: $id){
      id
      pk
      dateAdded
      dateModified
      leadType
      status
      fullName
      notes
      email
      phone
      message
      source
      referredBy
      formUrl
      isArchived
      isBlocked
      hasDuplicateEmail
      hasDuplicatePhone
      metadata
      documents {
        documentType
        documentUrl
        fileId
      }
      branch {
        id
        name
        country
      }
      assignedRep {
        id
        username
      }
      customer{
        id        
        dmvLicense
        tlcLicense
        dob
        firstName
        lastName
        phone
        email
        preferredLanguage
        age
        gender
        city
        state
        zipCode
        isTlc
        streetAddress
        canProcessInsurance
        branch{
          id
          tenant{
            id
          }
        }
        driverinsurancestatusSet {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    }
}`


const LeadStatus = gql`query Types{
  optionsList:__type(name: "LeadStatus") {
     states: enumValues {
         name
         description
     }
  }
 
}`

const LeadType = gql`query Types{
  optionsList:__type(name: "LeadLeadType") {
     states: enumValues {
         name
         description
     }
  }
 
}`

export { AllCustomerLeadsQuery, RepsQuery, TagsQuery, CustomerLeadQuery, LeadStatus, LeadType }
