import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Updated Apollo HOC import
import { Badge, Button, UncontrolledTooltip, Popover, PopoverBody, PopoverHeader, Label, Alert, Progress, FormGroup, Nav, NavItem, NavLink, Input, Form, TabContent, TabPane } from 'reactstrap'
import OverviewTab from './OverviewTab'
import { getMvrReportMutation, DeleteDriverMutation, UpdateDriver, ReActivateDriverOnApp } from "../Mutations"
import { ReactComponent as NotMobileUserIcon } from '../../../../assets/images/icons/notMobileUser.svg'
import { ReactComponent as MobileUserIcon } from '../../../../assets/images/icons/mobileUser.svg'
import ConfirmationPopup from "../../../../Material/ConfirmationPopup"
import DocumentsTab from "./DocumentTab/DocumentTab"
import HistoryTab from "./HistoryTab/HistoryTab"
import LeadsTab from "./LeadsTab/LeadsTab"
import BillingTab from "./BillingTab/BillingTab"
import DriverReservationTab from "./ReservationTab/DriverReservationTab"
import DriverRentalTab from "./RentalTab/DriverRentalTab"
import RelatedProfileTab from "./RelatedProfilesTab/RelatedProfilesTab"
import gql from 'graphql-tag'
import InteractionContainer from "../../../Interactions/InteractionsContainer"
import renderEnumToInt from "../../../../Functions/renderEnumToInt"
import ReservationModalNew from "../../../../Reservations/Modals/ReservationModalNew"
import './DriverDetailNew.css'
import CopyToClipboard from "../../../../Material/CopyToClipboard"
import { HasPermissionsQuery } from "../../../../Functions/querys"
import { ReactComponent as FrontIcon } from '../../../../assets/images/icons/frontapp-icon.svg'
import { ReactComponent as InOffice } from '../../../../assets/images/icons/in-office.svg'
import { ReactComponent as NoCard } from '../../../../assets/images/icons/no-card.svg'
import { StripeCards } from "../Queries"
import RiskTab from "./RiskTab/RiskTab"
import AddToDNR from "../../../DNR/AddToDNR"
import withApolloClient from "../../../../withApolloClient"
import PageCrash from "../../../../Components/PageCrash"
import { EMAIL_REGEX } from "../../../../Constants"

const DriverQuery = gql`query Driver($id: ID!){
    driver(id: $id){
        id
        pk
        name
        phone
        email
        canDelete
        dmvLicense
        frontLastMsg
        inOffice
        isBlocked
        isRelatedProfileBlocked
        geoLocation {
            latitude
            longitude
            timestamp
        }
        activeInsurance{
            id
        }
        currentAgreement{
            id
            agreementType
            car{
                id
                pk
                trackerLocation {
                    longitude
                    latitude
                }
            }
        }
        missingRentalDocuments{
            id
            typeName
        }
        mobileappdriver {
            id
            active
          }
        branch{
            id
            name
            requiresInsurance
        }
    }
}
`

const DRIVER_DETAIL_PAGE_TABS = [
    { id: "#overview", name: "OVERVIEW", querParams: false },
    { id: "#billing", name: "BILLING", queryParams: true },
    { id: "#rentals", name: "RENTALS", queryParams: true },
    { id: "#reservations", name: "RESERVATIONS", queryParams: true },
    { id: "#risk", name: "RISK", queryParams: false },
    { id: "#documents", name: "DOCUMENTS", queryParams: true },
    { id: "#interactions", name: "INTERACTIONS", queryParams: false },
    { id: "#history", name: "HISTORY", queryParams: false },
    { id: "#leads", name: "LEADS", queryParams: false },
    { id: "#relatedProfiles", name: "RELATED PROFILES", queryParams: false },
]
class DriverDetailContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: "",
            loading: false,
            runMvrLoading: false,
            error: "",
            input: {},
            openModal: "",
            rendererdTabPanes: [],
            emailVerified: false,
        }
    }
    updateInput = (e) => {
        let value = e.target.value
        let name = e.target.name
        if (name === "email") {
            if (EMAIL_REGEX.test(value))
                this.setState({ emailVerified: true, error: "" })
            else
                this.setState({ emailVerified: false, error: "Please enter a valid email address." })
        }
        this.setState({ value: value })
    };

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    updateDriver = (driverId, name, value) => {
        let defValue = this.state[name]
        try {
            if (driverId && name && value && value !== defValue) {
                this.setState({ loading: true })
                let input = {
                    id: driverId,
                    [name]: this.props.name === "stage" ? renderEnumToInt(value) : value
                }
                //Iagami - Code upgradation React 18
                this.props.apolloClient.mutate({
                    mutation: UpdateDriver,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateDriver && result.data.updateDriver.ok) {
                        this.setState({ editField: "", loading: false, error: "" })
                        this.props.refetchDriverQuery()

                    } else if (result && result.data && result.data.updateDriver && result.data.updateDriver.errors && result.data.updateDriver.errors[0] && result.data.updateDriver.errors[0].messages) {
                        this.setState({ loading: false, error: result.data.updateDriver.errors[0].messages.toString() })
                    } else {
                        this.setState({ loading: false, error: "Something went wrong, Please contact admin for support!" })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: err.message })
                })
            } else if (value === this.state.value) {
                this.setState({ editField: "", loading: false, error: "" })
            }
        } catch (err) {
            this.setState({ loading: false, error: err })
        }
    }
    getMvrReport = () => {
        this.setState({ runMvrLoading: true, error: "" })
        let input = { driverId: this.props.driver.id }
        this.props.apolloClient.mutate({
            mutation: getMvrReportMutation,
            variables: { input }
        }).then((result) => {
            if (result.data && result.data.getMvrReport && result.data.getMvrReport.ok && result.data.getMvrReport.reportData) {
                var a = window.document.createElement('a')
                a.href = "data:application/pdf;base64," + result.data.getMvrReport.reportData
                a.download = 'mvr.pdf'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                this.setState({ runMvrLoading: false })
            } else if (result.data && result.data.getMvrReport && result.data.getMvrReport.errors && result.data.getMvrReport.errors[0] && result.data.getMvrReport.errors[0].messages) {
                let error = result.data.getMvrReport.errors[0].messages.toString()
                this.setState({ runMvrLoading: false, error: error })
            } else {
                this.setState({ runMvrLoading: false, error: "An error has occured while running the MVR, please contact admin." })
            }
        })
    };
    deleteDriverProfile = (e) => {
        try {
            if (this.props.driver && this.props.driver.id) {
                this.setState({ loading: true })
                let input = {
                    id: this.props.driver ? this.props.driver.id : null,
                }
                this.props.apolloClient.mutate({
                    mutation: DeleteDriverMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.deleteDriver && result.data.deleteDriver.ok) {
                        this.setState({ error: "", openModal: "" })
                        window.open("/", "_self")
                    } else {
                        let error = "An error occurred, could not complete request."
                        if (result.data && result.data.deleteDriver && result.data.deleteDriver.errors && result.data.deleteDriver.errors[0] && result.data.deleteDriver.errors[0].messages) {
                            error = result.data.deleteDriver.errors[0].messages.toString()
                        }
                        this.setState({ error: error })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let error = "An error has occured: Please contact admin: " + err
                    this.setState({ error: error })
                    this.setState({ loading: false })
                })
            }
            else this.setState({ error: "Same Branch Selected" })
        }
        catch (err) {
            let error = "An error has occured: " + err
            this.setState({ error: error })
            this.setState({ loading: false })
        }
    }
    updateDriverAppStatus = () => {
        this.setState({ loading: true })
        let input = {
            'driverId': this.props.driver.id,
            'active': true
        }
        this.props.apolloClient.mutate({
            mutation: ReActivateDriverOnApp,
            variables: { input },
        }).then((result) => {
            if (result && result.data && result.data.setDriverActiveStatus && result.data.setDriverActiveStatus.ok) {
                this.props.refetchDriverQuery()
                this.setState({ loading: false, error: null })
            } else {
                this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
            }
        }).catch((err) => {
            this.setState({ loading: false, error: "An error has occurred. Please contact admin: " + err })
        })
    }
    editField(type, id) {
        if (id) {
            this.setState({ [type]: id, value: id })
        }
        if (type === this.state.editField) {
            this.setState({ error: null })
        }
        this.setState({ editField: type === this.state.editField ? "" : type })
    }

    toggleTab = (tabId) => {
        if (!this.state.rendererdTabPanes.includes(tabId)) {
            this.setState({ rendererdTabPanes: [...this.state.rendererdTabPanes, tabId] })
        }
        this.props.navigate({ hash: this.state.tab })
        this.setState({ tab: tabId })
    }

    componentDidMount() {
        const { apolloClient } = this.props
        let defaultTab = DRIVER_DETAIL_PAGE_TABS[0].id
        if (window.location.hash && DRIVER_DETAIL_PAGE_TABS.map(item => item.id).includes(window.location.hash)) {
            this.setState({ tab: window.location.hash, rendererdTabPanes: [window.location.hash] })
        } else {
            window.location.hash = defaultTab
            this.setState({ tab: defaultTab, rendererdTabPanes: [defaultTab] })
        }

    }
    componentDidUpdate(_, prevState) {
        if (this.state.tab !== prevState.tab) {
            this.props.navigate({
                hash: this.state.tab,
                search: DRIVER_DETAIL_PAGE_TABS.filter(obj => obj.queryParams)
                    .map(obj => obj.id)
                    .includes(this.state.tab) &&
                    this.state.rendererdTabPanes &&
                    this.state.rendererdTabPanes.includes(this.state.tab)
                    ? window.location.search
                    : "",
            })
        }
    }
    render() {
        return (
            <div className="driver-detail-container">
                {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                {this.props.driver === null && !this.props.loading ?
                    <PageCrash title={"Oops"} error={"Driver not found!"} /> :
                    this.props.driver &&
                    <div className="driver-header">
                        <span className="driver-id-wrapper">
                            {this.props.driver ? "#" + this.props.driver.pk : "--"}&nbsp;&nbsp;
                            <CopyToClipboard textToCopy={this.props.driver ? this.props.driver.pk : "--"} displayText={"driver id"} />
                        </span>
                        <div className="driver-image">
                            {this.props.driver && (this.props.driver.isBlocked || this.props.driver.isRelatedProfileBlocked) ?
                                <>
                                    <i id={"isBlocked"} className="fa fa-3x fa-ban" aria-hidden="true"
                                        style={{ color: this.props.driver.isBlocked ? "red" : "orange" }}>
                                    </i>
                                    <UncontrolledTooltip target={"isBlocked"}>
                                        {this.props.driver.isBlocked ? "Blocked" : "Related Profile Blocked"}
                                    </UncontrolledTooltip>
                                </> :
                                <i style={{ fontSize: "3em" }} className="fa fa-2x fa-user" aria-hidden="true"></i>
                            }
                        </div>
                        <div className="driver-name">
                            <label>
                                {this.props.driver && this.props.driver.name ? this.props.driver.name : ""}
                                <br />
                                <Badge color="primary" className="bos-badge-blue text-light">{this.props.driver && this.props.driver.branch ? this.props.driver.branch.name : "--"}</Badge>
                                &nbsp;&nbsp;
                                {this.props.driver && this.props.driver.mobileappdriver && this.props.driver.mobileappdriver.id ?
                                    <MobileUserIcon width='15' /> : <NotMobileUserIcon width='15' />
                                }
                                {this.props.driver && this.props.driver.frontLastMsg &&
                                    <>
                                        &nbsp;
                                        <UncontrolledTooltip target="front-conversation">Front Conversation</UncontrolledTooltip>
                                        <a id="front-conversation" target="_blank" href={this.props.driver.frontLastMsg.replace("api.", "").replace("messages", "open").replace("?referer=conversation", "")}><FrontIcon width='20' height="20" /></a>
                                    </>
                                }
                                {this.props.driver && this.props.driver.inOffice &&
                                    <>
                                        &nbsp;
                                        <InOffice id={"inOffice"} width={25} height={25} fill="#198754" />
                                        <UncontrolledTooltip target={"inOffice"}>Driver in Office</UncontrolledTooltip>
                                    </>
                                }{this.props.cards && this.props.cards.cards && this.props.cards.cards.length === 0 &&
                                    <>
                                        &nbsp;
                                        <NoCard id={"noCard"} width={25} height={25} />
                                        <UncontrolledTooltip target={"noCard"}>No Card</UncontrolledTooltip>
                                    </>
                                }
                            </label>
                        </div>
                        <div className="mr-auto">
                            <Form className="form-inline">
                                <FormGroup className="no-margin">
                                    <Input className="driver-header-input" disabled={this.state.editField !== "phone"} value={this.props.driver && this.props.driver.phone ? this.props.driver.phone : "--"} onChange={this.updateInput} id="phone" />
                                    <span style={{ display: "inline-flex", alignItems: "center" }}>
                                        {this.state.editField !== "phone" ?
                                            <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                &nbsp;
                                                <div className="driver-header-icon" style={{ borderRadius: "5px" }}>
                                                    <CopyToClipboard textToCopy={this.props.driver && this.props.driver.phone ? this.props.driver.phone : ""} displayText={"phone number"} />
                                                </div>
                                            </span>
                                            :
                                            <div>
                                                &nbsp;&nbsp;
                                                <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("phone")}></i>&nbsp;&nbsp;
                                                <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(this.props.driver.id, "phone", this.state.value)}></i>
                                            </div>
                                        }
                                    </span>
                                </FormGroup>
                                &nbsp;&nbsp;
                                <FormGroup className="no-margin">
                                    <Input className="driver-header-input" disabled={this.state.editField !== "email"} value={this.props.driver && this.props.driver.email ? this.props.driver.email : "--"} onChange={this.updateInput} id="email" name="email" />
                                    <span style={{ display: "inline-flex", alignItems: "center" }}>
                                        {this.state.editField !== "email" ?
                                            <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                &nbsp;
                                                <div className="driver-header-icon" style={{ borderRadius: "5px" }}>
                                                    <CopyToClipboard textToCopy={this.props.driver && this.props.driver.email ? this.props.driver.email : ""} displayText={"email"} />
                                                </div>
                                            </span>
                                            :
                                            <div>
                                                &nbsp;&nbsp;
                                                <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("email")}></i>&nbsp;&nbsp;
                                                {this.state.error === "" && <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(this.props.driver.id, "email", this.state.value)}></i>}
                                            </div>
                                        }
                                    </span>
                                </FormGroup>
                            </Form>
                        </div>
                        <div>
                            <Button
                                onClick={() => this.toggleModal("NewReservationModal")}
                                disabled={(this.props.driver && this.props.driver.missingRentalDocuments && this.props.driver.missingRentalDocuments.length > 0) || (!this.props.driver.branch.requiresInsurance ? this.props.driver.branch.requiresInsurance : !(this.props.driver.activeInsurance && this.props.driver.activeInsurance.length > 0))}
                                className={"ghost mb-0"}
                            >
                                {!this.props.driver.branch.requiresInsurance || (this.props.driver.activeInsurance && this.props.driver.activeInsurance.length > 0) ? ((this.props.driver && this.props.driver.missingRentalDocuments && this.props.driver.missingRentalDocuments.length > 0) ?
                                    "Missing Pickup Docs: " + this.props.driver.missingRentalDocuments.map(i => i.typeName).toString() : "RESERVE A CAR") : "No Active Insurance"}
                            </Button>&nbsp;&nbsp;
                            {this.state.openModal === "NewReservationModal" &&
                                <ReservationModalNew
                                    handleClose={() => this.toggleModal("")}
                                    open={this.state.openModal === "NewReservationModal"}
                                    title={"Create New Reservation"}
                                    driverId={this.props.driver.id}
                                    refetchQuery={this.props.refetchDriverQuery}
                                    isUpdate={false}
                                    skip={true}
                                    path={"driverDetail"}
                                />
                            }
                            <Button onClick={() => this.toggleModal("runDriverMVR")} id={"runDriverMVR"} className={"ghost mb-0"}>GENARATE MVR</Button> &nbsp;
                            {this.props.hasPermissions && this.props.hasPermissions.length > 0 && this.props.hasPermissions.includes("add_blockedcustomer") && !this.props.driver.isBlocked && <><Button onClick={() => this.toggleModal("addToDNRModal")} className={"ghost mb-0"}>ADD TO DNR</Button>&nbsp;</>}
                            {this.state.openModal === "addToDNRModal" &&
                                <AddToDNR
                                    handleClose={() => this.toggleModal("")}
                                    open={this.state.openModal === "addToDNRModal"}
                                    driverId={this.props.driver.id}
                                    refetchQuery={this.props.refetchDriverQuery}
                                />
                            }
                            <UncontrolledTooltip placement="top" target={"runDriverMVR"}>Genarate Driver MVR</UncontrolledTooltip>
                            <Popover placement="right" isOpen={this.state.openModal === "runDriverMVR"} target={"runDriverMVR"} toggle={() => this.toggleModal("")}>
                                <PopoverHeader onClick={() => this.toggleModal("")}>Run Driver MVR<i style={{ float: "right", marginLeft: 15 }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                                <PopoverBody>
                                    {this.state.runMvrLoading && <Progress animated color="info" value="100" />}
                                    {!this.state.runMvrLoading && this.state.error && <Alert color="danger"> {this.state.error} </Alert>}
                                    <FormGroup>
                                        <Label for="selectedBranch">ARE YOU SURE YOU WANT TO RUN MVR FOR {this.props.driver ? this.props.driver.name : "--"}?</Label>
                                        <br />
                                        <Button size="sm" color="primary" onClick={() => this.getMvrReport()} disabled={this.state.runMvrLoading}>{this.state.runMvrLoading ? "Loading..." : "YES"}</Button>&nbsp;&nbsp;
                                        {!this.state.runMvrLoading && <Button size="sm" color="secondary" onClick={() => this.toggleModal("")}>NO</Button>}
                                    </FormGroup>
                                </PopoverBody>
                            </Popover>
                            {this.props.driver && this.props.driver.mobileappdriver && !(this.props.driver.mobileappdriver.active) && <Button onClick={() => this.updateDriverAppStatus()} className={"ghost mb-0"}>Reactivate Driver's App</Button>}
                            {this.props.driver && this.props.driver.canDelete && this.props.hasPermissions && this.props.hasPermissions.length > 0 && this.props.hasPermissions.includes("custom_can_delete_driver") && <>
                                <Button onClick={() => this.toggleModal("deleteProfile")} id={"deleteProfile"} className={"mb-0"} color="danger"><i className="fa fa-trash" aria-hidden="true"></i></Button> &nbsp;
                                <UncontrolledTooltip placement="top" target={"deleteProfile"}>Delete Profile</UncontrolledTooltip>
                                {this.state.openModal === "deleteProfile" &&
                                    <ConfirmationPopup
                                        open={this.state.openModal === "deleteProfile"}
                                        message={"DELETING THIS DRIVER WILL ALSO DELETE ALL THE RELATED DOCUMENTS, INSURANCES AND OTHER DATA. ARE YOU SURE YOU WANT TO DELETE THIS DRIVER?"}
                                        loading={this.state.loading}
                                        handleClose={() => this.toggleModal("")}
                                        action={"Yes"}
                                        confirmAction={this.deleteDriverProfile}
                                        color="danger"
                                        error={this.state.error ? this.state.error : ""}
                                    />
                                }
                            </>
                            }
                        </div>
                    </div>
                }
                {this.props.driver && <div className="driver-detail">
                    <Nav tabs>
                        {DRIVER_DETAIL_PAGE_TABS.map(item =>
                            <NavItem key={item.id} className={this.state.tab === item.id ? "active" : "inactive"}>
                                <NavLink className={this.state.tab === item.id ? "active" : "inactive"} onClick={() => this.toggleTab(item.id)}>
                                    {item.name}
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                    <TabContent activeTab={this.state.tab}>
                        {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#overview") && <TabPane tabId="#overview">
                            <OverviewTab currentUser={this.props.currentUser} id={this.props.driver.id} toggleTab={(id) => this.toggleTab(id)} refetchQuery={this.props.refetchDriverQuery} />
                        </TabPane>}
                        {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#rentals") && <TabPane tabId="#rentals">
                            <DriverRentalTab currentUser={this.props.currentUser} id={this.props.driver.id} {...this.props} activeTab={this.state.tab.slice(1)} />
                        </TabPane>}
                        {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#reservations") && <TabPane tabId="#reservations">
                            <DriverReservationTab currentUser={this.props.currentUser} id={this.props.driver.id} {...this.props} activeTab={this.state.tab.slice(1)} />
                        </TabPane>}
                        {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#documents") && <TabPane tabId="#documents">
                            <DocumentsTab currentUser={this.props.currentUser} id={this.props.driver.id} activeTab={this.state.tab} {...this.props} />
                        </TabPane>}
                        {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#risk") && <TabPane tabId="#risk">
                            <RiskTab currentUser={this.props.currentUser} driverId={this.props.driver.id} />
                        </TabPane>}
                        {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#billing") && <TabPane tabId="#billing">
                            <BillingTab currentUser={this.props.currentUser} id={this.props.driver.id} {...this.props} activeTab={this.state.tab.slice(1)} />
                        </TabPane>}
                        {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#interactions") && <TabPane tabId="#interactions">
                            <InteractionContainer driverId={this.props.driver.id} interactionsType="driver" />
                        </TabPane>}
                        {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#history") && <TabPane tabId="#history">
                            <HistoryTab currentUser={this.props.currentUser} id={this.props.driver.id} />
                        </TabPane>}
                        {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#leads") && <TabPane tabId="#leads">
                            <LeadsTab currentUser={this.props.currentUser} id={this.props.driver.id} />
                        </TabPane>}
                        {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#relatedProfiles") && <TabPane tabId="#relatedProfiles">
                            <RelatedProfileTab currentUser={this.props.currentUser} id={this.props.driver.id} dmvLicense={this.props.driver && this.props.driver.dmvLicense ? this.props.driver.dmvLicense : ""} />
                        </TabPane>}
                    </TabContent>
                </div>}
            </div >
        )
    }
}

export default graphql(DriverQuery, {
    options: (props) => ({
        variables: { id: props && props.params ? props.params.id : null },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    }),
    props({ data: { driver, loading, refetch, variables } }) {
        return {
            driver,
            loading,
            variables,
            refetchDriverQuery: () => {
                return refetch({
                    query: DriverQuery,
                    variables: {
                        ...variables,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return { driver: fetchMoreResult.driver }
                    },
                })
            },
        }
    },
})(
    graphql(HasPermissionsQuery, {
        options: () => ({
            variables: {
                userPermissions: ["custom_can_delete_driver", "add_blockedcustomer"],
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        }),
        props({ data: { hasPermissions, loading, variables } }) {
            return {
                hasPermissions,
                loading,
                variables,
            }
        },
    })(
        graphql(StripeCards, {
            options: (props) => ({
                fetchPolicy: "cache-and-network",
                variables: { id: props && props.params ? props.params.id : null },
            }),
            props({ data: { loadingStripCards: loading, cards, variables } }) {
                return { loadingStripCards: loading, cards, variables }
            },
        })(withApolloClient(DriverDetailContainer))
    )
)
