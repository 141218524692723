import React, { Component } from 'react'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import { Row, Col, Alert, Progress, Label, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormText } from 'reactstrap'
import moment from 'moment-timezone'
import toTitleCase from '../../Functions/toTitleCase'
import Select from 'react-select'
import { HasPermissionsQuery } from "../../Functions/querys"
import withApolloClient from '../../withApolloClient' // Iagami - Code upgradation React 18

const AllCarDocumentTypesQuery = gql`
    query AllCarDocumentTypesQuery{
        allCarDocumentTypes(isActive:true){
            edges{
                node{
                  typeName
                  id
                  requiresExpiration
                  documentCategory {
                    id
                    name
                  }
                }
            }
      }
    }
`

const AllDriverDocumentTypesQuery = gql`query AllDriverDocumentTypesQuery($driverId:ID){
        allDriverDocumentTypes(isActive:true, driverId: $driverId){
            edges{
                node{
                  typeName
                  id
                }
            }
        }
    }
`

const UploadDocumentMutation = gql`
mutation uploadDocumentMutation($input: UploadDocumentMutationInput!){
    uploadDocument(input:$input){
    ok
    errors{
        messages
    }
}} `

const UploadRentalContractMutation = gql`
mutation UploadRentalContractMutation($input: UploadRentalContractMutationInput!){
    uploadRentalContract(input:$input){
    ok
    errors{
        messages
    }
}} `
class UploadDocumentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            documentUploaded: false,
            document: null,
            documentType: "",
            openModal: "",
            fileName: null,
            requiresExpiration: false,
            documentExpirationDate: null
        }
    }
    handleClose = (e) => {
        let state = {
            loading: false,
            error: null,
            documentUploaded: false,
            document: null,
            documentType: "",
            openModal: "",
            fileName: null,
            requiresExpiration: false,
            documentExpirationDate: null,
        }
        this.setState({ ...state })
        this.props.refetchQuery()
        this.props.handleClose()
    };
    componentDidMount() {
        const { apolloClient } = this.props;
    }        

    uploadDocument = () => {
        try {
            this.setState({ loading: true })
            if (!this.props.agreementId && !this.state.documentType || (this.state.requiresExpiration && !this.state.documentExpirationDate)) {
                this.setState({ loading: false, documentUploaded: false, error: "Missing input parameters, please put all the required details." })
            }
            let input = {
                file: this.state.document
            }
            if (this.props.agreementId) {
                input["agreementId"] = this.props.agreementId
            }
            else {
                input[this.props.objectType === "car" ? "carId" : "driverId"] = this.props.id
                input["documentTypeId"] = this.state.documentType
                input["fileName"] = this.state.fileName
                input["expiration"] = this.state.documentExpirationDate
            }
            this.props.apolloClient.mutate({
                mutation: this.props.agreementId ? UploadRentalContractMutation : UploadDocumentMutation,
                variables: { input },
            }).then((result) => {
                if (result.data && result.data.uploadDocument && result.data.uploadDocument.ok) {
                    this.setState({ loading: false, documentUploaded: true, error: null })
                    this.handleClose()
                } else if (result.data && result.data.uploadRentalContract && result.data.uploadRentalContract.ok) {
                    this.setState({ loading: false, documentUploaded: true, error: null })
                    this.handleClose()
                }
                else if (result && result.data && result.data.uploadDocument && result.data.uploadDocument.errors && result.data.uploadDocument.errors[0] && result.data.uploadDocument.errors[0].messages) {
                    this.setState({ loading: false, error: result.data.uploadDocument.errors[0].messages.toString() })
                } else if (result && result.data && result.data.uploadRentalContract && result.data.uploadRentalContract.errors && result.data.uploadRentalContract.errors[0] && result.data.uploadRentalContract.errors[0].messages) {
                    this.setState({ loading: false, error: result.data.uploadRentalContract.errors[0].messages.toString() })
                }
                else {
                    this.setState({ loading: false, documentUploaded: false, error: "An error has occured. Please contact system admin" })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, documentUploaded: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, documentUploaded: false, })
        }
    };

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    updateDocument = (e) => {
        let base64Img = e.target.files[0]
        let name = e.target.files[0].name
        let _this = this
        var reader = new FileReader()
        reader.readAsDataURL(base64Img)
        reader.onload = function (e) {
            let imageInBase64 = reader.result
            imageInBase64 = imageInBase64.substring(imageInBase64.indexOf(',') + 1)
            _this.setState({ document: imageInBase64, fileName: name })
        }
    }

    updateDocType = (documentTypeId) => {
        let documentTypes = this.props.objectType === "car" ? this.props.allCarDocumentTypes : this.props.allDriverDocumentTypes
        let selectedType = documentTypes.edges.filter(type => type.node.id === documentTypeId)[0]
        this.setState({ documentType: documentTypeId, requiresExpiration: selectedType.node.requiresExpiration })
    }

    render() {
        let documentTypes =
            this.props.objectType === "car" ?
                this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_upload_tlc_docs") ?
                    this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges && this.props.allCarDocumentTypes.edges.length > 0 && this.props.allCarDocumentTypes.edges :
                    this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges && this.props.allCarDocumentTypes.edges.length > 0 && this.props.allCarDocumentTypes.edges.filter(carDocumentType => carDocumentType.node.typeName.toLowerCase() != "registration" && carDocumentType.node.documentCategory && carDocumentType.node.documentCategory.name != "TLC") :
                this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 && this.props.agreementId ? this.props.allDriverDocumentTypes.edges.filter(item => item.node.typeName.toLowerCase() === "contract") : this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 &&
                    this.props.allDriverDocumentTypes.edges.filter(item => item.node.typeName.toLowerCase() !== "contract")
        return (
            <div>
                <Modal className="uploadDocument" isOpen={this.props.open}>
                    {this.props.id && <>
                        <ModalHeader>Upload Document For {toTitleCase(this.props.objectType)} </ModalHeader>
                        <ModalBody>
                            {this.state.error && !this.state.documentUploaded && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                            {this.state.documentUploaded && !this.state.error && <Row><Col xs={12}><Alert color="success">Document Uploaded successfully!</Alert></Col></Row>}
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="documentType">Select Document Type</Label>
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            isLoading={this.props.allCarDocumentTypesLoading || this.props.allDriverDocumentTypesLoading}
                                            options={documentTypes && documentTypes.length > 0 ? documentTypes.map(item => ({ value: item.node.id, label: item.node.typeName })) : []}
                                            placeholder="Select Document Type"
                                            onChange={docType => this.updateDocType(docType.value)}
                                            defaultValue={this.props.agreementId ? { value: "", label: 'Contract' } : null}
                                            value={this.props.agreementId ? { value: "", label: 'Contract' } : documentTypes && documentTypes.length > 0 && documentTypes.find(item => item.node.id === this.state.documentType) ? { value: this.state.documentType, label: documentTypes && documentTypes.length > 0 && documentTypes.find(item => item.node.id === this.state.documentType).node.typeName } : null}
                                            isDisabled={!!this.props.agreementId}
                                        />
                                    </FormGroup>
                                    {this.state.document && <img src={"data:image/jpeg;base64," + this.state.document} width="100" />}
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="document">Select File</Label>
                                        <Input type="file" name="document" id="document" onChange={this.updateDocument} accept=".pdf, image/*" />
                                        <FormText color="muted">
                                            Accepted files include pdf,jpg,jpeg and pngs
                                        </FormText>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    {this.state.requiresExpiration && (!this.state.expiration ?
                                        <Alert color="info">An expiration date is required for this document type</Alert>
                                        : (!moment(this.state.expiration, moment.HTML5_FMT.DATE, true).isValid() || (moment().add(10, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE) > this.state.expiration) &&
                                            <Alert color="info">Please enter a date that's 10 days from today!</Alert>))
                                    }
                                    {this.state.requiresExpiration && <FormGroup>
                                        <Label for="documentExpirationDate">Expiration date</Label>
                                        <Input type="date" min={moment().add(10, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE)} value={this.state.documentExpirationDate} onChange={(e) => this.setState({ documentExpirationDate: e.target.value })} />
                                    </FormGroup>}
                                </Col>
                            </Row>
                            {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                        </ModalBody>
                    </>}
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button color="secondary" onClick={this.uploadDocument}
                                        disabled={
                                            this.props.agreementId
                                                ? !this.state.document || this.state.documentUploaded || this.state.loading
                                                : !this.state.document || !this.state.documentType || this.state.documentUploaded || this.state.loading ||
                                                (this.state.requiresExpiration &&
                                                    (!this.state.documentExpirationDate || !moment(this.state.documentExpirationDate, moment.HTML5_FMT.DATE, true).isValid() || (moment().add(10, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE) > this.state.documentExpirationDate))
                                                )
                                        }
                                    >Upload Document</Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

// Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllCarDocumentTypesQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data: { allCarDocumentTypesLoading: loading, allCarDocumentTypes } }) => ({
        loading,
        allCarDocumentTypes,
    }),
})(
    graphql(AllDriverDocumentTypesQuery, {
        options: ({ id }) => ({
            variables: { driverId: id },
            fetchPolicy: "cache-first",
            notifyOnNetworkStatusChange: true,
        }),
        props: ({ data: { allDriverDocumentTypesLoading: loading, allDriverDocumentTypes } }) => ({
            loading,
            allDriverDocumentTypes,
        }),
    })(
        graphql(HasPermissionsQuery, {
            options: () => ({
                variables: { userPermissions: ["custom_can_upload_tlc_docs"] },
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
            }),
            props: ({ data: { hasPermissions, loading, variables } }) => ({
                hasPermissions,
                loading,
                variables,
            }),
        })(withApolloClient(UploadDocumentModal))
    )
);
