import React, { useState, useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Button, ModalHeader, ModalBody, Modal, ModalFooter, Progress } from 'reactstrap'
import DenyInsurance from '../ApplicationsNew/DenyInsurance'
import SubmitToInsuranceForm from '../ApplicationsNew/SubmitToInsuranceForm'
import "./ProcessInsuranceModal.css"

const DriverQuery = gql`
  query DriverQuery($id: ID) {
    driver(id: $id) {
      id
      pk
      branch {
         id
         name
         country
       }

      missingInsuranceDocuments {
        id
        typeName
      }
    }
  }
`

const DriverDocumentDataQuery = gql`
  query DriverDocumentData($driverId: ID!) {
    driverDocumentData(driverId: $driverId) {
      documentData {
        documentName
        requiredData {
          displayName
          value
        }
      }
    }
  }
`

const ProcessInsuranceModal = ({ open, handleClose, id: driverId, refetchQuery }) => {
    const [openModal, setOpenModal] = useState("")
    const [missingInsuranceDocuments, setMissingInsuranceDocuments] = useState([])
    const [missingDocumentFields, setMissingDocumentFields] = useState([])
    const isMissingInsuranceDocuments = missingInsuranceDocuments.length > 0
    const isMissingFields = missingDocumentFields.length > 0
    const { data: driverData, loading: driverDataLoading } = useQuery(DriverQuery, { variables: { id: driverId }, })
    const { data, loading: driverDocumentDataLoading } = useQuery(DriverDocumentDataQuery, { variables: { driverId: driverId }, })
    const isLoading = driverDataLoading || driverDocumentDataLoading

    useEffect(() => {
        if (driverData?.driver?.missingInsuranceDocuments) {
            setMissingInsuranceDocuments(driverData.driver.missingInsuranceDocuments)
        }

        if (data?.driverDocumentData?.documentData) {
            const missingFields = data?.driverDocumentData?.documentData.flatMap(doc =>
                doc.requiredData
                    .filter(field => !field.value || field.value === "")
                    .map(field => ({
                        documentName: doc.documentName,
                        fieldName: field.displayName,
                    }))
            )
            setMissingDocumentFields(missingFields)
        }
    }, [data, driverData])

    const handleModalClose = () => {
        setOpenModal("")
        handleClose?.()
    }

    const toggleModal = (modalName) => {
        setOpenModal((prev) => (prev === modalName ? "" : modalName))
    }

    const groupedMissingFields = useMemo(() => {
        return missingDocumentFields.reduce((acc, field) => {
            if (!acc[field.documentName]) {
                acc[field.documentName] = []
            }
            acc[field.documentName].push(field)
            return acc
        }, {})
    }, [missingDocumentFields])

    return (
        <Modal isOpen={open} id={"process-insurance-modal-popover-contianer"}>
            {openModal === "DenyInsurance" && (
                <DenyInsurance
                    refetchDriverQuery={refetchQuery}
                    handleClose={handleModalClose}
                    open={openModal === "DenyInsurance"}
                    driverId={driverId}
                />
            )}
            {openModal === "SubmitToInsuranceForm" && (
                <SubmitToInsuranceForm
                    refetchDriverQuery={refetchQuery}
                    handleClose={handleModalClose}
                    open={openModal}
                    driverId={driverId}
                    driver={driverData?.driver}
                />
            )}
            <ModalHeader id={"process-insurance-modal-popover-header"}>Process Insurance</ModalHeader>
            <ModalBody id={"process-insurance-modal-popover-body"}>
                {isMissingFields && (
                    <>
                        <p style={{ color: "red" }}>The following fields are empty and must be filled before continuing:</p>
                        {Object.entries(groupedMissingFields).map(([documentName, fields], index) => (
                            <div key={index}>
                                <p style={{ color: "red" }}>
                                    <strong>{documentName}</strong>
                                </p>
                                <ul>
                                    {fields.map((field, index) => (
                                        <li key={index} style={{ color: "red" }}>{field.fieldName}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </>
                )}
                {isMissingInsuranceDocuments && (
                    <>
                        <p style={{ color: "red" }}>Insurance cannot be processed due to the absence of the following required customer documents.</p>
                        <ul>
                            {missingInsuranceDocuments.map((document, index) => (
                                <li key={index} style={{ color: "red" }}>
                                    <strong>{document.typeName}</strong>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                <div className="process-insurance-modal-btn-container">
                    <Button
                        size="sm"
                        onClick={() => toggleModal("DenyInsurance")}
                        className={"insurance-primary-btn"}
                        style={{ backgroundColor: "red" }}
                    >
                        Deny Insurance
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                        size="sm"
                        onClick={() => toggleModal("SubmitToInsuranceForm")}
                        className={"insurance-primary-btn"}
                        disabled={isMissingInsuranceDocuments || isMissingFields || isLoading}
                    >
                        Request Approval
                    </Button>
                </div>
                {isLoading && <Progress animated color="info" value="100" />}
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleClose} className={"process-insurance-modal-secondary-btn"}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ProcessInsuranceModal
