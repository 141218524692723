import { LocationQuery, CarDealEntityQuery, CarBaseQuery, CarDealEntityTypes, AllEquipmentItemsGroup, AllLocationsQuery, AllBranchesQuery, AllOwnersQuery, AllBrokersQuery, InsurancePolicyStatuses, AllCarIssueCategoriesQuery, AllVendorsQuery, AllInsurancePoliciesQuery, AllEquipmentItems, AllBrokers, GlobalSettings, AllSystemUsers, AllChargeTypes, ChargeTypeBucket, ChargeTypeCategory, AllGroupTypes, ChargeTypes, AllAgreementTypes, AgreementTypeChargeType, AgreementTypeChargeFrequencyUnit, AgreementTypeCustomerType, AgreementTypeDepositScheme, AllRegistrationTypes, RegistrationTypeTypeFor, AllTags, AllTagTypes, AllContentTypes, AllDriverDocumentTypes, AllDocumentCategory, AllCarDocumentTypes, AllScheduleClosingDays, AllSubscriptionTypes, SubscriptionTypeInterval, SubscriptionTypeSubscriptionManager, CarIssueCategoryType, AllBusinessHours, BusinessHourDayOfWeek, AllReturnTimeSlots, ChargeTypeDueDateSchedule, AgreementTypeBillingDay, AllBlockedReasons, GroupTypeDueDateSchedule } from './Queries'
import { CreateCarDealEntity, UpdateCarDealEntity, CreateGlobalSettings, UpdateGlobalSettings, CreateCarEquipmentItem, UpdateCarEquipmentItem, CreateCarLocation, CreateOwner, CreateInsurancePolicy, CreateCarIssueCategory, CreateVendor, UpdateVendor, CreateBase, UpdateCarLocation, CreateBroker, UpdateBroker, UpdateInsurancePolicy, UpdateBase, UpdateOwner, UpdateCarIssueCategory, CreateChargeType, UpdateChargeType, CreateGroupType, UpdateGroupType, CreateAgreementType, UpdateAgreementType, CreateRegistrationType, CreateColorTag, UpdateColorTag, CreateDriverDocumentType, UpdateDriverDocumentType, CreateCarDocumentType, UpdateCarDocumentType, CreateDocumentCategory, UpdateDocumentCategory, CreateTagType, UpdateTagType, CreateScheduledClosingDay, UpdateScheduledClosingDay, CreateSubscriptionType, UpdateSubscriptionType, CreateBusinessHour, UpdateBusinessHour, CreateReturnTimeSlot, UpdateReturnTimeSlot, CreateBlockedReason, UpdateBlockedReason } from './Mutations'

const AttributeConfigurations = {
    /*
        *Instructions & Rules*
        1- title: is being used in headings, and buttons text
        2- query: is being used to fetch the listing
        3- createMutation: {
            mutation: is being used to create the object
            inputFields: is being used to render the input form for object creation. "Make sure that we have all these fields in either tableHeaders.id or tableHeaders.inputIdentifier"
            requiredFields: is being used to get the required fields. These fields must exist in the inputFields array
        }
        4- updateMutation: {
            mutation: is being used to update the object
            inputFields: is being used to render the input form for object creation. "Make sure that we have all these fields in either tableHeaders.id or tableHeaders.inputIdentifier"
            requiredFields: is being used to get the required fields. These fields must exist in the inputFields array
        }
        5- tableHeaders: [
            {
                id: is being used to get the details from the data object. It will access the data from multiple depths of the object like car.model.name or car.current_agreement.driver.name
                name: is being used as the header of the table
                inputIdentifier: is being used for inputNames if the id is complex. e.g for carLocation.pickupInstructions.message we can not identify the inputParam for update/create so we initialize the name as pickupInstructionsId using this attribute
                type: is being used for input type. Default type is text.
                optionsQuery: if the type is select then we need to pass the query through which we can get the options list
                optionSelector: is being used for identifying which object parameter should be use as the value. Label will always be the "name" attribute of the object
            }
        ]
        6- Must Pass default value if type = "boolean"
     */
    coloredTags: {
        title: "Colored Tags",
        query: AllTags,
        permissions: {
            get: "view_tag",
            post: "add_tag",
            update: "change_tag"
        },
        createMutation: {
            mutation: CreateColorTag,
            inputFields: ["name", "contentTypeId", "removeUponReturn", "color", "tagTypeId"],
            requiredFields: ["name", "contentTypeId", "removeUponReturn"]
        },
        updateMutation: {
            mutation: UpdateColorTag,
            inputFields: ["name", "contentTypeId", "removeUponReturn", "color", "tagTypeId"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", sortable: true },
            { id: "name", name: "Name", sortable: true },
            { id: "removeUponReturn", name: "Remove Upon Return", type: "boolean", defaultValue: false, sortable: false },
            { id: "color", name: "Color", sortable: true },
            { id: "contentType.model", inputIdentifier: "contentTypeId", name: "Content Type", type: "select", optionsQuery: AllContentTypes, optionSelector: "id", sortable: true },
            { id: "tagType.name", inputIdentifier: "tagTypeId", name: "Tage Type", type: "select", optionsQuery: AllTagTypes, optionSelector: "id", sortable: true },
        ]
    },
    carLocation: {
        title: "Car Location",
        query: LocationQuery,
        permissions: {
            get: "view_carlocation",
            post: "add_carlocation",
            update: "change_carlocation"
        },
        createMutation: {
            mutation: CreateCarLocation,
            inputFields: ["name", "address", "isPickupLocation", "isReturnLocation", "driverCanBookOnline", "defaultReturnLocationId", "branchId"],
            requiredFields: ["name", "address", "branchId"],
        },
        updateMutation: {
            mutation: UpdateCarLocation,
            inputFields: ["id", "address", "isPickupLocation", "isReturnLocation", "driverCanBookOnline", "defaultReturnLocationId", "isActive", "branchId"],
            requiredFields: ["id"],
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", sortable: true },
            { id: "name", name: "Name", sortable: true },
            { id: "address", name: "Address" },
            { id: "isActive", name: "Active", type: "boolean", defaultValue: true },
            { id: "isPickupLocation", name: "Is Pickup Location", type: "boolean", defaultValue: false },
            { id: "isReturnLocation", name: "Is Return Location", type: "boolean", defaultValue: false },
            { id: "driverCanBookOnline", name: "Driver Can Book Online", type: "boolean", defaultValue: false },
            { id: "defaultReturnLocation.name", inputIdentifier: "defaultReturnLocationId", name: "Return Location", type: "select", sortable: true, optionsQuery: AllLocationsQuery, optionSelector: "id" },
            { id: "branch.name", inputIdentifier: "branchId", name: "Branch Name", type: "select", optionsQuery: AllBranchesQuery, optionSelector: "id" },
        ]
    },

    carDealEntity: {
        title: "Car Deal Entity",
        query: CarDealEntityQuery,
        permissions: {
            get: "view_cardealentity",
            post: "add_cardealentity",
            update: "change_cardealentity"
        },
        createMutation: {
            mutation: CreateCarDealEntity,
            inputFields: ["name", "entityType"],
            requiredFields: ["name", "entityType"],
        },
        updateMutation: {
            mutation: UpdateCarDealEntity,
            inputFields: ["id", "entityType", "isActive"],
            requiredFields: ["id"],
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", sortable: true },
            { id: "name", name: "Name", sortable: true },
            { id: "dateAdded", name: "Date Added", type: "datetime", sortable: true },
            { id: "dateModified", name: "Last Modified", type: "datetime", sortable: true },
            { id: "entityType", name: "Entity Type", type: "select", optionsQuery: CarDealEntityTypes, optionSelector: "description", sortable: true },
            { id: "isActive", name: "Is Active", type: "boolean", defaultValue: true },
        ]
    },

    carBase: {
        title: "Car Base",
        query: CarBaseQuery,
        createMutation: {
            mutation: CreateBase,
            inputFields: ["id", "name"],
            requiredFields: ["name"]
        },
        permissions: {
            get: "view_base",
            post: "add_base",
            update: "change_base"
        },
        updateMutation: {
            mutation: UpdateBase,
            inputFields: ["isActive"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", sortable: true },
            { id: "name", name: "Name", sortable: true },
            { id: "dateAdded", name: "Date Added", type: "datetime", sortable: true },
            { id: "dateModified", name: "Last Modified", type: "datetime", sortable: true },
            { id: "isActive", name: "Is Active", type: "boolean" },
        ]
    },

    plateOwner: {
        title: "Plate Owner",
        query: AllOwnersQuery,
        permissions: {
            get: "view_plate",
            post: "add_plate",
            update: "change_plate"
        },
        createMutation: {
            mutation: CreateOwner,
            inputFields: ["id", "name"],
            requiredFields: ["name"],
        },
        updateMutation: {
            mutation: UpdateOwner,
            inputFields: ["id", "isActive"],
            requiredFields: ["id"],
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", sortable: true },
            { id: "name", name: "Name", sortable: true },
            { id: "dateAdded", name: "Date Added", type: "datetime", sortable: true },
            { id: "dateModified", name: "Last Modified", type: "datetime", sortable: true },
            { id: "isActive", name: "Is Active", type: "boolean" },
        ]
    },

    carInsurancePolicy: {
        title: "Car Insurance Policy",
        query: AllInsurancePoliciesQuery,
        permissions: {
            get: "view_insurance",
            post: "add_insurance",
            update: "change_insurance"
        },
        createMutation: {
            mutation: CreateInsurancePolicy,
            inputFields: ["policyNumber", "startDate", "endDate", "brokerId"],
            requiredFields: ["policyNumber", "startDate", "endDate", "brokerId"]
        },
        updateMutation: {
            mutation: UpdateInsurancePolicy,
            inputFields: ["id", "startDate", "endDate", "brokerId", "status"],
            requiredFields: ["id"]
        },

        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", sortable: true },
            { id: "dateAdded", name: "Date Added", type: "datetime", sortable: true },
            { id: "dateModified", name: "Last Modified", type: "datetime", sortable: true },
            { id: "policyNumber", name: "Policy Number", sortable: true },
            { id: "broker.name", inputIdentifier: "brokerId", name: "Broker", type: "select", optionsQuery: AllBrokersQuery, optionSelector: "id", sortable: true },
            { id: "startDate", name: "Start Date", type: "date", sortable: true },
            { id: "endDate", name: "End Date", type: "date", sortable: true },
            { id: "status", name: "Status", type: "select", optionsQuery: InsurancePolicyStatuses, optionSelector: "name", fetchValue: true, sortable: true },
        ]
    },

    issueCategory: {
        title: "Issue Category",
        query: AllCarIssueCategoriesQuery,
        permissions: {
            get: "view_carissuecategory",
            post: "add_carissuecategory",
            update: "change_carissuecategory"
        },
        createMutation: {
            mutation: CreateCarIssueCategory,
            inputFields: ["category", "description", "type", "requiredClosingProof", "outOfService"],
            requiredFields: ["category", "description", "type"]
        },
        updateMutation: {
            mutation: UpdateCarIssueCategory,
            inputFields: ["description", "requiredClosingProof", "type", "outOfService"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", sortable: true },
            { id: "category", name: "Issue Category Name", sortable: true },
            { id: "dateAdded", name: "Date Added", type: "datetime", sortable: true },
            { id: "dateModified", name: "Last Modified", type: "datetime", sortable: true },
            { id: "description", name: "Issue Description", sortable: true },
            { id: "type", name: "Type", type: "select", optionsQuery: CarIssueCategoryType, optionSelector: "description", sortable: false },
            { id: "outOfService", name: "Out Of Service", type: "boolean", defaultValue: true },
            { id: "requiredClosingProof", name: "Closing Proof", type: "boolean", defaultValue: true },
        ]
    },
    carVendor: {
        title: "Car Vendor",
        query: AllVendorsQuery,
        permissions: {
            get: "view_vendor",
            post: "add_vendor",
            update: "change_vendor"
        },
        createMutation: {
            mutation: CreateVendor,
            inputFields: ["name", "address", "notes"],
            requiredFields: ["name"]
        },
        updateMutation: {
            mutation: UpdateVendor,
            inputFields: ["id", "address", "notes", "isActive"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", sortable: true },
            { id: "name", name: "Vendor Name", sortable: true },
            { id: "dateAdded", name: "Date Added", type: "datetime", sortable: true },
            { id: "dateModified", name: "Last Modified", type: "datetime", sortable: true },
            { id: "address", name: "Address" },
            { id: "notes", name: "Notes" },
            { id: "isActive", name: "Is Active", type: "boolean", defaultValue: true }
        ]
    },
    carEquipments: {
        title: "Car Equipments",
        query: AllEquipmentItems,
        permissions: {
            get: "view_equipmentitems",
            post: "add_equipmentitems",
            update: "change_equipmentitems"
        },
        createMutation: {
            mutation: CreateCarEquipmentItem,
            inputFields: ["name", "description", "equipmentItemsGroupId"],
            requiredFields: ["equipmentItemsGroupId"]
        },
        updateMutation: {
            mutation: UpdateCarEquipmentItem,
            inputFields: ["id", "description", "equipmentItemsGroupId"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", sortable: true },
            { id: "name", name: "Name", sortable: true },
            { id: "description", name: "Description", sortable: true },
            { id: "equipmentItemsGroup.name", name: "Type", inputIdentifier: "equipmentItemsGroupId", type: "select", optionsQuery: AllEquipmentItemsGroup, optionSelector: "id", sortable: true }
        ]
    },
    globalSettings: {
        title: "Global Settings",
        query: GlobalSettings,
        permissions: {
            get: "view_globalsettings",
            post: "add_globalsettings",
            update: "change_globalsettings"
        },
        createMutation: {
            mutation: CreateGlobalSettings,
            inputFields: ["settingsName", "settingsValue"],
            requiredFields: ["settingsName", "settingsValue"]
        },
        updateMutation: {
            mutation: UpdateGlobalSettings,
            inputFields: ["id", "settingsValue"],
            requiredFields: ["id", "settingsValue"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", sortable: true },
            { id: "settingsName", name: "Name", sortable: true },
            { id: "dateAdded", name: "Date Added", type: "datetime", sortable: true },
            { id: "dateModified", name: "Last Modified", type: "datetime", sortable: true },
            { id: "settingsValue", name: "Metadata", type: "textarea" },
        ]
    },
    allBrokers: {
        title: "Insurance Brokers",
        query: AllBrokers,
        permissions: {
            get: "view_broker",
            post: "add_broker",
            update: "change_broker"
        },
        createMutation: {
            mutation: CreateBroker,
            inputFields: ["id", "name", "address", "notes", "isActive", "isMain", "isUnderwriter", "notes"],
            requiredFields: ["name"]
        },
        updateMutation: {
            mutation: UpdateBroker,
            inputFields: ["id", "address", "notes", "isActive", "isMain", "isUnderwriter", "notes"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", sortable: true },
            { id: "name", name: "Name", sortable: true },
            { id: "dateAdded", name: "Date Added", type: "datetime", sortable: true },
            { id: "dateModified", name: "Last Modified", type: "datetime", sortable: true },
            { id: "address", name: "Address" },
            { id: "isActive", name: "Is Active", type: "boolean", defaultValue: true, sortable: true },
            { id: "isMain", name: "Is Main", type: "boolean", defaultValue: true, sortable: true },
            { id: "isUnderwriter", name: "Underwriter", type: "boolean", defaultValue: true, sortable: true },
            { id: "notes", name: "Notes" },
        ]
    },
    AllSystemUsers: {
        title: "System Users",
        query: AllSystemUsers,
        permissions: {
            get: "view_user",
            post: "add_user",
            update: "change_user"
        },
        createMutation: {
            //mutation: CreateVendor,
            // inputFields: ["name", "address", "notes"],
            // requiredFields: ["name"]
        },
        updateMutation: {
            // mutation: UpdateVendor, 
            // inputFields: ["id", "name", "address", "notes", "isActive"],
            // requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "username", name: "Username", sortable: true },
            { id: "dateJoined", name: "Date Joined", type: "datetime", sortable: true },
            { id: "isActive", name: "Is Active", type: "boolean", defaultValue: true, sortable: true },
            { id: "isStaff", name: "Is Staff", type: "boolean", defaultValue: true, sortable: true },
            { id: "isTopManagement", name: "Top Management", type: "boolean", defaultValue: true, sortable: true },
        ]
    },
    AllChargeTypes: {
        title: "Billing Charge Types",
        query: AllChargeTypes,
        permissions: {
            get: "view_chargetype",
            post: "add_chargetype",
            update: "change_chargetype"
        },
        createMutation: {
            mutation: CreateChargeType,
            inputFields: ["name", "description", "defaultAmount", "minimumAmount", "maximumAmount", "bucket", "category", "dueDateSchedule", "isActive", "increaseBalance", "paymentPlanCompatible"],
            requiredFields: ["name", "description", "defaultAmount", "bucket", "category", "isActive", "increaseBalance", "dueDateSchedule", "paymentPlanCompatible"]
        },
        updateMutation: {
            mutation: UpdateChargeType,
            inputFields: ["description", "defaultAmount", "minimumAmount", "maximumAmount", "bucket", "category", "dueDateSchedule", "paymentPlanCompatible", "isActive"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID" },
            { id: "dateAdded", name: "Date Added", type: "datetime", sortable: true },
            { id: "name", name: "Name", sortable: true },
            { id: "description", name: "Description", sortable: true },
            // { id: "dateModified", name: "Last Modified", type: "datetime", sortable: true },
            { id: "defaultAmount", name: "Default Amount", sortable: true },
            { id: "minimumAmount", name: "Minimum Amount", sortable: true },
            { id: "maximumAmount", name: "Maximum Amount", sortable: true },
            { id: "bucket", name: "Bucket", type: "select", optionsQuery: ChargeTypeBucket, optionSelector: "description", sortable: true },
            { id: "category", name: "Category", type: "select", optionsQuery: ChargeTypeCategory, optionSelector: "description", sortable: true },
            { id: "dueDateSchedule", name: "Due Date Schedule", type: "select", optionsQuery: ChargeTypeDueDateSchedule, optionSelector: "description", sortable: true },
            { id: "increaseBalance", name: "Increase Balance", type: "boolean", defaultValue: true, sortable: true },
            { id: "paymentPlanCompatible", name: "Payment Plan Compatible", type: "boolean", defaultValue: false, sortable: true },
            { id: "isActive", name: "Is Active", type: "boolean", defaultValue: true, sortable: true },
        ]
    },

    AllGroupTypes: {
        title: "Billing Group Types",
        query: AllGroupTypes,
        permissions: {
            get: "view_grouptype",
            post: "add_grouptype",
            update: "change_grouptype"
        },
        createMutation: {
            mutation: CreateGroupType,
            inputFields: ["name", "description", "defaultAmount", "minimumAmount", "maximumAmount", "dueDateSchedule", "isActive", "chargeTypeId"],
            requiredFields: ["name", "description", "defaultAmount", "dueDateSchedule", "isActive", "chargeTypeId"]
        },
        updateMutation: {
            mutation: UpdateGroupType,
            inputFields: ["description", "defaultAmount", "minimumAmount", "maximumAmount", "dueDateSchedule", "isActive", "chargeTypeId"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID" },
            { id: "name", name: "Name", sortable: true },
            { id: "description", name: "Description", sortable: true },
            { id: "dateAdded", name: "Date Added", type: "datetime", sortable: true },
            { id: "dateModified", name: "Last Modified", type: "datetime", sortable: true },
            { id: "defaultAmount", name: "Default Amount", sortable: true },
            { id: "minimumAmount", name: "Minimum Amount", sortable: true },
            { id: "maximumAmount", name: "Maximum Amount", sortable: true },
            { id: "dueDateSchedule", name: "Due Date Schedule", type: "select", optionsQuery: GroupTypeDueDateSchedule, optionSelector: "description", sortable: true },
            { id: "chargeType.name", inputIdentifier: "chargeTypeId", name: "Charge Type", type: "select", optionsQuery: ChargeTypes, optionSelector: "id", sortable: true },
            { id: "isActive", name: "Is Active", type: "boolean", defaultValue: true, sortable: true },
        ]
    },

    AllAgreementTypes: {
        title: "Agreement Types",
        query: AllAgreementTypes,
        permissions: {
            get: "view_agreementtype",
            post: "add_agreementtype",
            update: "change_agreementtype"
        },
        createMutation: {
            mutation: CreateAgreementType,
            inputFields: ["name", "branchId", "salesTax", "rentalSalesTax", "depositScheme", "requiredDeposit", "chargeType", "customerType", "initialChargeFrequency", "chargeFrequency", "chargeFrequencyUnit", "billingDay", "dueDateOffset", "isActive", "notes", "requireEndDate", "forLeaseToOwn"],
            requiredFields: ["name", "branchId", "salesTax", "rentalSalesTax", "depositScheme", "requiredDeposit", "chargeType", "customerType", "chargeFrequency", "chargeFrequencyUnit", "isActive"]
        },
        updateMutation: {
            mutation: UpdateAgreementType,
            inputFields: ["salesTax", "branchId", "rentalSalesTax", "depositScheme", "requiredDeposit", "chargeType", "customerType", "chargeFrequency", "chargeFrequencyUnit", "dueDateOffset", "isActive", "notes", "requireEndDate", "forLeaseToOwn"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID" },
            { id: "dateAdded", name: "Date Added", type: "datetime", sortable: true },
            { id: "dateModified", name: "Last Modified", type: "datetime", sortable: true },
            { id: "name", name: "Name", sortable: true },
            { id: "salesTax", name: "Sales Tax", sortable: true, type: "number" },
            { id: "rentalSalesTax", name: "Rental Sales Tax", sortable: true, type: "number" },
            { id: "depositScheme", name: "Deposit Scheme", type: "select", optionsQuery: AgreementTypeDepositScheme, optionSelector: "description", sortable: true },
            { id: "requiredDeposit", name: "Required Deposit", sortable: true, type: "number" },
            { id: "billingDay", name: "Billing Day", type: "select", optionsQuery: AgreementTypeBillingDay, optionSelector: "name", fetchValue: true, sortable: true },
            { id: "dueDateOffset", name: "Due Date Offset", sortable: true, type: "number" },
            { id: "customerType", name: "Customer Type", type: "select", optionsQuery: AgreementTypeCustomerType, optionSelector: "description", sortable: true },
            { id: "chargeType", name: "Charge Type", type: "select", optionsQuery: AgreementTypeChargeType, optionSelector: "description", sortable: true },
            { id: "initialChargeFrequency", name: "Inital Charge Frequency", sortable: true, type: "number" },
            { id: "chargeFrequency", name: "Charge Frequency", sortable: true, type: "number" },
            { id: "chargeFrequencyUnit", name: "Charge Frequency Unit", type: "select", optionsQuery: AgreementTypeChargeFrequencyUnit, optionSelector: "description", sortable: true },
            { id: "branch.name", inputIdentifier: "branchId", name: "Branch Name", type: "select", optionsQuery: AllBranchesQuery, optionSelector: "id" },
            { id: "isActive", name: "Is Active", type: "boolean", defaultValue: true, sortable: true },
            { id: "requireEndDate", name: "End Date", type: "boolean", defaultValue: false, sortable: false },
            { id: "forLeaseToOwn", name: "For Lease To Own", type: "boolean", defaultValue: false, sortable: false },
            { id: "notes", name: "Notes", sortable: true },
        ]
    },

    RegistrationType: {
        title: "Registration Type",
        query: AllRegistrationTypes,
        permissions: {
            get: "view_registrationtype",
            post: "add_registrationtype",
            update: "change_registrationtype"
        },
        createMutation: {
            mutation: CreateRegistrationType,
            inputFields: ["name", "typeFor", "stateCode"],
            requiredFields: ["name", "typeFor", "stateCode"]
        },
        updateMutation: {
            // mutation: UpdateVendor, 
            // inputFields: ["id", "name", "address", "notes", "isActive"],
            // requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID" },
            { id: "name", name: "Name" },
            { id: "dateAdded", name: "Date Added", type: "datetime" },
            { id: "stateCode", name: "State Code" },
            { id: "isActive", name: "Is Active", type: "boolean", defaultValue: true },
            { id: "typeFor", name: "Type For", type: "select", optionsQuery: RegistrationTypeTypeFor, optionSelector: "description", fetchValue: true, },
        ]
    },

    AllDriverDocumentType: {
        title: "Driver Document Types",
        query: AllDriverDocumentTypes,
        permissions: {
            get: "view_driverdocumenttype",
            post: "add_driverdocumenttype",
            update: "change_driverdocumenttype"
        },
        createMutation: {
            mutation: CreateDriverDocumentType,
            inputFields: ["name", "documentCategoryId", "allowMultiple", "visibleInDriverApp"],
            requiredFields: ["name", "documentCategoryId"]
        },
        updateMutation: {
            mutation: UpdateDriverDocumentType,
            inputFields: ["id", "documentCategoryId", "allowMultiple", "visibleInDriverApp"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID" },
            { id: "name", name: "Name" },
            { id: "documentCategory.name", inputIdentifier: "documentCategoryId", type: "select", name: "Document Category", optionsQuery: AllDocumentCategory, optionSelector: "id" },
            { id: "dateAdded", name: "Date Added", type: "datetime" },
            { id: "dateModified", name: "Date Modified", type: "datetime" },
            { id: "allowMultiple", name: "Allow Multiple", type: "boolean", defaultValue: true },
            { id: "visibleInDriverApp", name: "Visible In Driver App", type: "boolean", defaultValue: true },
        ]
    },

    AllCarDocumentType: {
        title: "Car Document Types",
        query: AllCarDocumentTypes,
        permissions: {
            get: "view_cardocumenttype",
            post: "add_cardocumenttype",
            update: "change_cardocumenttype"
        },
        createMutation: {
            mutation: CreateCarDocumentType,
            inputFields: ["name", "documentCategoryId", "allowMultiple", "visibleInDriverApp", "requiresExpiration"],
            requiredFields: ["name", "documentCategoryId"]
        },
        updateMutation: {
            mutation: UpdateCarDocumentType,
            inputFields: ["id", "documentCategoryId", "allowMultiple", "visibleInDriverApp", "requiresExpiration"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID" },
            { id: "name", name: "Name" },
            { id: "documentCategory.name", inputIdentifier: "documentCategoryId", type: "select", name: "Document Category", optionsQuery: AllDocumentCategory, optionSelector: "id" },
            { id: "dateAdded", name: "Date Added", type: "datetime" },
            { id: "dateModified", name: "Date Modified", type: "datetime" },
            { id: "allowMultiple", name: "Allow Multiple", type: "boolean", defaultValue: true },
            { id: "requiresExpiration", name: "Require Expiration", type: "boolean", defaultValue: true },
            { id: "visibleInDriverApp", name: "Visible In Driver App", type: "boolean", defaultValue: true },
        ]
    },

    AllDocumentTypeCategories: {
        title: "Document Categories",
        query: AllDocumentCategory,
        permissions: {
            get: "view_documentcategory",
            post: "add_documentcategory",
            update: "change_documentcategory"
        },
        createMutation: {
            mutation: CreateDocumentCategory,
            inputFields: ["name", "ddescription"],
            requiredFields: ["name"]
        },
        updateMutation: {
            mutation: UpdateDocumentCategory,
            inputFields: ["id", "ddescription"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID" },
            { id: "name", name: "Name" },
            { id: "ddescription", name: "Description" },
            { id: "dateAdded", name: "Date Added", type: "datetime" },
            { id: "dateModified", name: "Date Modified", type: "datetime" },
        ]
    },

    AllTagTypes: {
        title: "Tag Types",
        query: AllTagTypes,
        permissions: {
            get: "view_tagtype",
            post: "add_tagtype",
            update: "change_tagtype"
        },
        createMutation: {
            mutation: CreateTagType,
            inputFields: ["name", "description"],
            requiredFields: ["name"]
        },
        updateMutation: {
            mutation: UpdateTagType,
            inputFields: ["id", "description"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID" },
            { id: "name", name: "Name" },
            { id: "description", name: "Description" },
        ]
    },

    AllScheduledClosingDay: {
        title: "Scheduled Closing Day",
        query: AllScheduleClosingDays,
        permissions: {
            get: "view_scheduleclosingday",
            post: "add_scheduleclosingday",
            update: "change_scheduleclosingday"
        },
        createMutation: {
            mutation: CreateScheduledClosingDay,
            inputFields: ["reason", "branchId", "scheduleDate"],
            requiredFields: ["reason", "branchId", "scheduleDate"]
        },
        updateMutation: {
            mutation: UpdateScheduledClosingDay,
            inputFields: ["id", "reason", "branchId", "scheduleDate"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID" },
            { id: "reason", name: "Reason" },
            { id: "branch.name", inputIdentifier: "branchId", name: "Branch Name", type: "select", optionsQuery: AllBranchesQuery, optionSelector: "id" },
            { id: "scheduleDate", name: "Scheduled Date", type: "date" },
            { id: "dateAdded", name: "Date Added", type: "datetime" },
            { id: "dateModified", name: "Date Modified", type: "datetime" },
        ]
    },
    AllSubscriptionTypes: {
        title: "Subscription Type",
        query: AllSubscriptionTypes,
        permissions: {
            get: "view_subscriptiontype",
            post: "add_subscriptiontype",
            update: "change_subscriptiontype"
        },
        createMutation: {
            mutation: CreateSubscriptionType,
            inputFields: ["name", "amount", "deductibleAmount", "interval", "intervalFrequency", "retryLimit", "customId", "subscriptionManager", "isActive", "agreementTypeId", "description"],
            requiredFields: ["name", "amount", "deductibleAmount", "interval", "intervalFrequency", "retryLimit", "subscriptionManager", "isActive", "agreementTypeId"]
        },
        updateMutation: {
            mutation: UpdateSubscriptionType,
            inputFields: ["id", "name", "amount", "deductibleAmount", "interval", "intervalFrequency", "retryLimit", "customId", "subscriptionManager", "isActive", "agreementTypeId", "description"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", },
            { id: "name", name: "Name", sortable: true },
            { id: "amount", name: "Amount" },
            { id: "deductibleAmount", name: "Deductible Amount" },
            { id: "interval", name: "Interval", type: "select", optionsQuery: SubscriptionTypeInterval, optionSelector: "description", sortable: true },
            { id: "intervalFrequency", name: "Inetrval Frequency" },
            { id: "retryLimit", name: "Retry Limit" },
            { id: "customId", name: "Custom ID" },
            { id: "subscriptionManager", name: "Subscription Manager", type: "select", optionsQuery: SubscriptionTypeSubscriptionManager, optionSelector: "description", sortable: true },
            { id: "isActive", name: "Is Active", type: "boolean", defaultValue: true, sortable: true },
            { id: "description", name: "Description", sortable: true },
            { id: "agreementType.name", inputIdentifier: "agreementTypeId", name: "Agreement Type", type: "select", optionsQuery: AllAgreementTypes, optionSelector: "id" },
            { id: "dateAdded", name: "Date Added", type: "datetime" },
            { id: "dateModified", name: "Date Modified", type: "datetime" },
        ]
    },

    // AllBusinessHours: {
    //     title: "Business Hours",
    //     query: AllBusinessHours,
    //     permissions: {
    //         get: "view_businesshour",
    //         post: "add_businesshour",
    //         update: "change_businesshour"
    //     },
    //     createMutation: {
    //         mutation: CreateBusinessHour,
    //         inputFields: ["dayOfWeek", "openTime", "closeTime", "branchId"],
    //         requiredFields: ["dayOfWeek", "openTime", "closeTime", "branchId"]
    //     },
    //     updateMutation: {
    //         mutation: UpdateBusinessHour,
    //         inputFields: ["businessHourId", "dayOfWeek", "openTime", "closeTime", "branchId"],
    //         requiredFields: ["businessHourId"]
    //     },
    //     tableHeaders: [
    //         { id: "pk", inputIdentifier: "businessHourId", name: "ID", },
    //         { id: "dayOfWeek", name: "Day Of Week", type: "select", optionsQuery: BusinessHourDayOfWeek, optionSelector: "name", fetchValue: true },
    //         { id: "branch.name", inputIdentifier: "branchId", name: "Branch", type: "select", optionsQuery: AllBranchesQuery, optionSelector: "id" },
    //         { id: "openTime", name: "Open Time", type: "time" },
    //         { id: "closeTime", name: "Close Time", type: "time" },
    //         { id: "dateAdded", name: "Date Added", type: "datetime" },
    //         { id: "dateModified", name: "Date Modified", type: "datetime" },
    //     ]
    // },

    AllReturnTimeSlots: {
        title: "Return Time Slots",
        query: AllReturnTimeSlots,
        permissions: {
            get: "view_returntimeslot",
            post: "add_returntimeslot",
            update: "change_returntimeslot"
        },
        createMutation: {
            mutation: CreateReturnTimeSlot,
            inputFields: ["locationId", "startTime", "availableSlots"],
            requiredFields: ["locationId", "startTime", "availableSlots"]
        },
        updateMutation: {
            mutation: UpdateReturnTimeSlot,
            inputFields: ["returnTimeSlotId", "locationId", "startTime", "availableSlots"],
            requiredFields: ["returnTimeSlotId"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "returnTimeSlotId", name: "ID", },
            { id: "location.name", inputIdentifier: "locationId", name: "Location", type: "select", optionsQuery: AllLocationsQuery, optionSelector: "id", sortable: true },
            { id: "startTime", name: "Open Time", type: "time" },
            { id: "availableSlots", name: "Available Slots", type: "number", sortable: true },
            { id: "location.branch.name", name: "Branch Name" },
        ]
    },
    AllBlockedReasons: {
        title: "All Blocked Reasons",
        query: AllBlockedReasons,
        permissions: {
            get: "view_blockedreason",
            post: "add_blockedreason",
            update: "change_blockedreason"
        },
        createMutation: {
            mutation: CreateBlockedReason,
            inputFields: ["name", "description"],
            requiredFields: ["name", "description"]
        },
        updateMutation: {
            mutation: UpdateBlockedReason,
            inputFields: ["id", "name", "description"],
            requiredFields: ["id"]
        },
        tableHeaders: [
            { id: "pk", inputIdentifier: "id", name: "ID", },
            { id: "name", name: "Name" },
            { id: "description", name: "Description" },
        ]
    },
}
//Iagami - Code upgradation React 18
export default AttributeConfigurations
