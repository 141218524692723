import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Updated import for graphql HOC
import { Button, Nav, NavItem } from 'reactstrap'
import './CollectionsListContainer.css'
import { Container, Row, Col } from 'reactstrap'
import DriverListHeader from '../DriversNew/DriverListHeader'
import DriversList from '../DriversNew/DriversList'
import { HasPermissionsQuery } from "../../Functions/querys"
import ManagePreferenceHOC from "../../Material/ManagePreferenceHOC"
import PaymentSchedulesList from "../../Billing/PaymentSchedules/PaymentSchedulesList"
import PaymentPlanListContainer from "./PaymentPlanListContainer"
import DriverActions from "../DriversNew/DriverActions"
import { useNavigate } from "react-router-dom"
// import PaymentSchedulesList from '../../Billing/PaymentSchedules/PaymentSchedulesList' // check this component

const currentDate = new Date()

const tabs = [
    {
        id: '#collection',
        name: 'Collections',
        filters: {
            stages: [6],
            hideFromCollections: false,
            orderBy: ["-in_office", "-id"],
            chargeDayofweek: currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1,
            needsCollection: true,
        }, iconClassName: "fa fa-usd"
    },
    { id: "#disabled_repo", name: "Disabled/Repo", filters: { orderBy: ["driver__in_office", "-id"], carStatusIn: ["Disabled", "Repo"] }, iconClassName: "fa fa-ban" },
    { id: "#schedules", name: "Schedules", filters: { orderBy: ["driver__in_office", "-id"], statuses: ["Scheduled"] }, iconClassName: "fa fa-clock-o" },
    { id: "#paymentplans", name: "Payment Plans", filters: { orderBy: ["-id"], statuses: ["Active"] }, iconClassName: "fa fa-calendar-o" },
]

const tableHeaders = [
    { id: 'id', name: 'Driver Info', sortable: true, visible: true, showInPicker: false, positonIsFixed: true },
    { id: 'branch__name', name: 'Branch', sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: 'auto_charge', name: 'AutoCharge', sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: 'balance', name: 'Balance', sortable: true, visible: true, showInPicker: true, positonIsFixed: false },
    { id: 'weekly_billing_notes', name: 'Billing Notes', sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: 'tags', name: 'Tags', sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: 'assigned_to__name', name: 'Assigned Rep', sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
]
const CollectionsListContainerNew = (props) => {
    const navigate = useNavigate()
    return <CollectionsListContainerNewNavigate {...props} navigate={navigate} />
}
class CollectionsListContainerNewNavigate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedDrivers: [],
            activeTab: 'Collections',
            orderBy: ['-in_office', '-id'],
            openModal: '',
            filterValues: {},
            driverCountOnPage: 0,
            totalDriverCount: 0,
            refetchDrivers: false,
        }
    }
    updateOrder = (string) => {
        let order = [...this.state.orderBy]
        if (!order.includes(string) && !order.includes("-" + string)) {
            order = [order.find(item => item.includes("-in_office")), string]
        } else {
            let index = order.indexOf(string)
            if (order.indexOf(string) > -1) {
                order[index] = "-" + string
            } else {
                index = order.indexOf("-" + string)
                index > -1 && (order[index] = string)
            }
        }
        this.setState({ orderBy: order })
    }


    toggleModal = (openState, modalName) => {
        if (openState === true && this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }
    isAuthorized = () => {
        let isAuthorized = false
        if (this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_auto_charge")) {
            isAuthorized = true
        }
        return isAuthorized
    }

    componentDidMount() {
        if (window.location.hash && tabs.map((item) => item.id).includes(window.location.hash)) {
            const tabIndex = tabs.findIndex((tab) => tab.id === window.location.hash)
            this.setState({ activeTab: tabs[tabIndex].name })
        } else {
            window.location.hash = tabs[0].id
            this.setState({ activeTab: tabs[0].name })
        }
    }

    changeTab = (e) => {
        const { name, value } = e.target
        if (value !== this.state.activeTab) {
            const tabIndex = tabs.findIndex((tab) => tab.name === value)
            window.location.hash = tabs[tabIndex].id
            this.setState({ [name]: value, filterValues: { ...tabs[tabIndex].filters } })
        }
    };


    render() {
        const isAuthorized = this.isAuthorized()
        return (
            <Container fluid className="collectionsListContainer">
                <Nav pills className="available-car-wrap">
                    {tabs.map(tab =>
                        <NavItem key={tab.id}>
                            <Button color="primary" name={"activeTab"} value={tab.name} className={this.state.activeTab === tab.name ? "active" : "inactive"} onClick={this.changeTab}>
                                <i className={tab.iconClassName}></i>  {tab.name}
                            </Button>
                        </NavItem>
                    )}
                </Nav>
                {this.state.activeTab !== "Payment Plans" &&
                    <Row>
                        <Col xs={8}>
                            <DriverListHeader
                                activeTab={this.state.activeTab}
                                filterValues={this.state.filterValues}
                                setFilterValues={(filterValues) => this.setState({ filterValues })}
                                defaultFilters={this.state.activeTab && tabs.find(tab => tab.name === this.state.activeTab).filters}
                                resetSelectedDrivers={() => this.setState({ selectedDrivers: [] })}
                                {...this.props}
                            />
                        </Col>
                        <Col xs={4}>
                            <DriverActions
                                activeTab={this.state.activeTab}
                                selectedDrivers={this.state.selectedDrivers}
                                resetSelectedDrivers={() => this.setState({ selectedDrivers: [] })}
                                driverCountOnPage={this.state.driverCountOnPage}
                                totalDriverCount={this.state.totalDriverCount}
                                refetchQuery={() => this.setState({ refetchDrivers: !this.state.refetchDrivers })}
                                currentUser={this.props.currentUser}
                                filterValues={this.state.filterValues}
                                columnConfig={this.props.columnConfig}
                                preferenceType={"collection"}
                                refetchPreferences={this.props.refetchPreferences}
                                {...this.props}
                            />
                        </Col>
                    </Row>
                }
                {["Collections", "Disabled/Repo"].includes(this.state.activeTab) ?
                    <Row>
                        <Col>
                            <DriversList
                                currentUser={this.props.currentUser}
                                orderBy={this.state.orderBy}
                                updateOrder={this.updateOrder}
                                columnConfig={this.props.columnConfig}
                                isBillingAndAuthorized={isAuthorized}
                                selectedDrivers={this.state.selectedDrivers}
                                setDriverCountOnPage={(count) => this.setState({ driverCountOnPage: count })}
                                setTotalDriverCount={(count) => this.setState({ totalDriverCount: count })}
                                setSelectedDrivers={(selectedDrivers) => this.setState({ selectedDrivers })}
                                hideFromCollections={this.state.filterValues && this.state.filterValues.hideFromCollections !== null ? this.state.filterValues.hideFromCollections : null}
                                minBalance={this.state.filterValues && this.state.filterValues.minBalance ? parseFloat(this.state.filterValues.minBalance) : null}
                                maxBalance={this.state.filterValues && this.state.filterValues.maxBalance ? parseFloat(this.state.filterValues.maxBalance) : null}
                                balanceGt={this.state.active && this.state.activeTab === "Collections" ? 0 : null}
                                assignedTo={this.state.filterValues && this.state.filterValues.assignedTo ? this.state.filterValues.assignedTo : null}
                                hasBalanceWriteOff={this.state.filterValues && this.state.filterValues.hasBalanceWriteOff !== null ? this.state.filterValues.hasBalanceWriteOff : null}
                                hasPaymentPlan={this.state.filterValues && this.state.filterValues.hasPaymentPlan !== null ? this.state.filterValues.hasPaymentPlan : null}
                                autoCharge={this.state.filterValues && this.state.filterValues.autoCharge !== null ? this.state.filterValues.autoCharge : null}
                                stages={this.state.filterValues && this.state.filterValues.stages ? this.state.filterValues.stages : null}
                                tags={this.state.filterValues && this.state.filterValues.driverTags ? this.state.filterValues.driverTags : null}
                                searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                                chargeDayofweek={this.state.filterValues && this.state.filterValues.chargeDayofweek !== null ? this.state.filterValues.chargeDayofweek : null}
                                carStatusIn={this.state.filterValues && this.state.filterValues.carStatusIn ? this.state.filterValues.carStatusIn : null}
                                needsCollection={this.state.filterValues && this.state.filterValues.needsCollection !== null ? this.state.filterValues.needsCollection : null}
                                refetchDrivers={this.state.refetchDrivers}
                                setRefetchQuery={() => this.setState({ refetchDrivers: !this.state.refetchDrivers })}
                                listingType="collections"
                            />
                        </Col>
                    </Row>
                    : this.state.activeTab === "Schedules" ? <Row>
                        <Col>
                            <PaymentSchedulesList
                                key={String("true")}
                                updateOrder={this.updateOrder}
                                drvierId={this.state.filterValues && this.state.filterValues.driverId ? this.state.filterValues.driverId : null}
                                orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : []}
                                statuses={this.state.filterValues && this.state.filterValues.statuses && this.state.filterValues.statuses.length > 0 ? this.state.filterValues.statuses : []}
                                searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                                currentUser={this.props.currentUser}
                            />
                        </Col>
                    </Row> :
                        <Row>
                            <Col>
                                <PaymentPlanListContainer
                                    currentUser={this.props.currentUser}
                                    location={this.props.location}
                                    history={this.props.history}
                                />
                            </Col>
                        </Row>
                }

            </Container>
        )
    }
}

export default graphql(HasPermissionsQuery, {
    options: { variables: { userPermissions: ['custom_can_auto_charge'] }, fetchPolicy: 'network-only' },
    props: ({ data: { hasPermissions, loading, variables } }) => ({
        hasPermissions,
        loading,
        variables
    }),
})(ManagePreferenceHOC('collection', tableHeaders)(CollectionsListContainerNew))
