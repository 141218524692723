/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Button, Row, Col, FormGroup } from 'reactstrap'
import Select from 'react-select'
import { CarDocumentsList, DriverDocumentsList } from "./DocumentsListNew"
import UploadDocumentModal from './UploadDocumentModal'
import { AllCarDocumentTypesQuery, AllDriverDocumentTypesQuery } from "./Quries"
import { HasPermissionsQuery } from "../../Functions/querys"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

class DocumentListContainerNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            documentTypes: [],
            docCats: [],
            isArchived: false,
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return <>
            <Row>
                {!this.props.hideFilters && <>
                    {this.props.objectType === "car" &&
                        <Col className={"text-left "} xs={this.props.colSize == "12" ? "6" : ""}>
                            <FormGroup>
                                <Select
                                    className="bos-custom-select" classNamePrefix="bos-select"
                                    isLoading={this.props.allCarDocumentTypesLoading}
                                    options={this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges && this.props.allCarDocumentTypes.edges.length > 0 && this.props.allCarDocumentTypes.edges.map(item => ({ value: item.node.id, label: item.node.typeName }))}
                                    placeholder="Car Document Types"
                                    isMulti
                                    isClearable
                                    onChange={types => this.setState({ documentTypes: (types.map(item => item.value)) })} />
                            </FormGroup>
                        </Col>
                    }
                    {this.props.objectType === "driver" &&
                        <Col className={"text-left "} xs={this.props.colSize == "12" ? "6" : ""}>
                            <FormGroup>
                                <Select style={{overflow:"visible", maxWidth:"100%"}}
                                    className="bos-custom-select" classNamePrefix="bos-select"
                                    isLoading={this.props.allDriverDocumentTypesLoading}
                                    options={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 ? this.props.allDriverDocumentTypes.edges.map(item => ({ value: item.node.id, label: item.node.typeName })) : []}
                                    placeholder="Driver Document Types"
                                    isMulti
                                    isClearable
                                    onChange={types => this.setState({ documentTypes: (types.map(item => item.value)) })} />
                            </FormGroup>
                        </Col>
                    }
                    <Col className={"text-left pt-1"} xs={this.props.colSize == "12" ? "6" : ""}>
                        <small>Exclude Archived</small>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                className="toggle-switch-checkbox"
                                name="isArchived"
                                id="isArchived"
                                checked={this.state.isArchived}
                                onChange={() => this.setState({ isArchived: !this.state.isArchived })}
                            />
                            <label className="toggle-switch-label" htmlFor="isArchived">
                                <span className="toggle-switch-inner" />
                                <span className="toggle-switch-switch" />
                            </label>
                        </div>
                        <small>Show Archived</small>
                    </Col>
                </>}
                <Col className={"text-right"} xs={this.props.colSize == "12" ? "12" : ""}>
                    <Button className="upload-document" color="primary" onClick={() => this.toggleModal("UploadDocumentModal")}>Upload New Doc</Button>
                </Col>
            </Row>
            {this.state.openModal === "UploadDocumentModal" &&
                <UploadDocumentModal
                    handleClose={() => this.toggleModal("UploadDocumentModal")}
                    open={this.state.openModal === "UploadDocumentModal"}
                    objectType={this.props.objectType}
                    id={this.props.id}
                    refetchQuery={this.props.refetchOnUpdate ? this.props.refetchOnUpdate : () => { return true }}
                />}

            {this.props.objectType === "driver" &&
                <DriverDocumentsList
                    driverId={this.props.id}
                    typesIn={this.state.documentTypes}
                    isArchived={this.state.isArchived}
                    currentUser={this.props.currentUser}
                />
            }
            {this.props.objectType === "car" &&
                <CarDocumentsList
                    carId={this.props.id}
                    carPk={this.props.carPk}
                    typesIn={this.state.documentTypes}
                    isArchived={this.state.isArchived}
                    currentUser={this.props.currentUser}
                />
            }
        </>
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllCarDocumentTypesQuery, {
    props: ({ data: { allCarDocumentTypes, loading } }) => ({
        allCarDocumentTypesLoading: loading,
        allCarDocumentTypes,
    }),
})(
    graphql(AllDriverDocumentTypesQuery, {
        options: ({ id }) => ({
            variables: { driverId: id },
            fetchPolicy: "network-only",
            notifyOnNetworkStatusChange: true,
        }),
        props: ({ data: { loading, allDriverDocumentTypes } }) => ({
            allDriverDocumentTypesLoading: loading,
            allDriverDocumentTypes,
        }),
    })(
        graphql(HasPermissionsQuery, {
            options: () => ({
                variables: { userPermissions: ["custom_can_view_tlc_docs"] },
                fetchPolicy: "network-only",
                notifyOnNetworkStatusChange: true,
            }),
            props: ({ data: { hasPermissions, loading, variables } }) => ({
                hasPermissions,
                loading,
                variables,
            }),
        })(withApolloClient(DocumentListContainerNew))
    )
)