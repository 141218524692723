import React, { useState } from 'react'
import { Button, Modal, ModalBody, Label, Input, Progress } from "reactstrap"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"

const ViewDocumentInfo = ({ img, open, title, handleClose, identifier }) => {

    const [ocrError, setOcrError] = useState(null)
    const [ocrSuccessMessage, setOcrSuccessMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const isImage = (url, name) => {
        const regex = /\.(jpg|jpeg|png|gif|bmp)$/i
        return regex.test(url || '') || regex.test(name || '')
    }

    const cleanDocumentUrl = (url) => {
        // Prepare document urls by removing unnecessary parts
        url = url.replace('%2F', '/')
        url = url.replace('&export=download', '')
        url = url.replace('https://storage.googleapis.com/download/storage/v1/b/buggy_files/o', 'https://storage.googleapis.com/buggy_files')
        // Remove everythin starting from ?generation= until the end of the string
        url = url.replace(/\?generation=.*/g, '')
        return url
    }
    return (
        <Modal
            className="DocViewerModal"
            isOpen={open}
            toggle={() => handleClose()}
            centered
        >
            <div className="DocViewerModalHeader">
                <p className="DocViewerModalTitle">
                    {identifier.referenceDocument ? identifier.referenceDocument.name : "Customer Indentifier"}
                </p>
                <div className="DocViewerModalActions">
                    <p><i className="fa fa-times" onClick={() => handleClose()}></i></p>
                </div>
            </div>
            <ModalBody className="DocViewerModalBody">
                <div className="IdnModalBody">
                    {
                        identifier.referenceDocument ? (
                            isImage(identifier.referenceDocument.documentUrl, identifier.referenceDocument.name) ? (
                                <img className="DocViewerImage" src={identifier.referenceDocument.documentUrl} alt="Document" />
                            ) : (
                                <embed
                                    scrolling="yes"
                                    className="IdnPdfViewer"
                                    width="100%"
                                    height="800px"
                                    src={cleanDocumentUrl(identifier.referenceDocument.documentUrl)}
                                ></embed>
                            )
                        ) : (
                            <p>No reference document available</p>
                        )
                    }
                    <h4 className="IdnModalInfoTitle">Customer Identifier Info</h4>
                    <div className="IdnModalInfo">
                        <div className="IdnModalInfoItem">
                            <Label>Type</Label>
                            <Input disabled={true} defaultValue={identifier && identifier.identifierType.typeName ? identifier.identifierType.typeName : "--"} />
                        </div>
                        <div className="IdnModalInfoItem">
                            <Label>Value</Label>
                            <Input disabled={true} defaultValue={identifier && identifier.value ? identifier.value : "--"} />
                        </div>
                        <div className="IdnModalInfoItem">
                            <Label>Status</Label>
                            <Input disabled={true} defaultValue={identifier && identifier.status ? identifier.status : "--"} />
                        </div>
                        <div className="IdnModalInfoItem">
                            <Label>Date of Upload</Label>
                            <DatetimeRenderer datetime={identifier.dateAdded ? identifier.dateAdded : "--"} />
                        </div>
                        <div className="IdnModalInfoItem">
                            <Label>Issued Date</Label>
                            <Input disabled={true} defaultValue={identifier && identifier.issuedDate ? identifier.issuedDate : "--"} />
                        </div>
                        <div className="IdnModalInfoItem">
                            <Label>Expiration Date</Label>
                            <Input disabled={true} defaultValue={identifier && identifier.expirationDate ? identifier.expirationDate : "--"} />
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ViewDocumentInfo
