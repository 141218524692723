import React, { Component } from 'react'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc' // Updated Apollo HOC import
import { useState, useCallback } from 'react'
import Loader from "../../../../../Material/Loader"
import {
    CardHeader,
    Card,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    Popover,
    PopoverBody,
    UncontrolledTooltip, Spinner
} from 'reactstrap'
import withApolloClient from "../../../../../withApolloClient"
import { AllPalencaAccountsQuery } from "../../../../../CustomerLeads/Queries"

const AllArgyleAccountsQuery = gql`
query AllArgyleAccounts($argyleUserId: String){
    allArgyleAccounts(argyleUserId: $argyleUserId){
        accountId
        employers
        userId
        source
        connectionStatus
        connectionCode
    }
}`

const BranchDetails = gql`
query BranchDetails($id: ID!){
    branch(id: $id) {
        id
        name
        branchSettings {
            edges {
                node {
                    id
                    value
                    key {
                        id
                        name
                    }
                }
            }
        }
    }
}`


const UpdateDriver = gql`
    mutation updateDriverMutation($input: UpdateDriverMutationInput!){
    updateDriver(input:$input){
        ok
        errors{
            messages
            }
        }
    } `


export function ArgyleAccountPopOver({ isOpen, toggle, target, employer, status, statusCode, lastSuccessfulConnection, accountId }) {

    return (
        <Popover placement="bottom" target={target} isOpen={isOpen} toggle={toggle}>
            <PopoverBody>
                {/* Show information about status, account name and source. One data point per line */}
                <Row>
                    <Col xs={12} className="d-flex justify-content-between">
                        <span>Account Name</span>
                        {accountId
                            ? <a target="_blank" href={`https://www.console.palenca.com/logins/details/${accountId}`}>{employer || ""}</a>
                            : <span>{employer || ""}</span>
                        }

                    </Col>
                    <Col xs={12} className="d-flex justify-content-between">
                        <span>Status</span>
                        <span>{status ? status.toUpperCase() : ""}</span>
                    </Col>
                    {statusCode &&
                        <Col xs={12} className="d-flex justify-content-between">
                            <span>Source</span>
                            <span>{statusCode}</span>
                        </Col>
                    }
                    {lastSuccessfulConnection &&
                        <Col xs={12} className="d-flex justify-content-between">
                            <span>Last Successful connection</span>
                            <span>{new Date(lastSuccessfulConnection).toISOString().split("T")[0]}</span>
                        </Col>
                    }
                </Row>
            </PopoverBody>
        </Popover>
    )
}

export function ArgyleCard(props) {
    const [popoverOpen, setPopoverOpen] = useState("")
    const [selectedAccount, setSelectedAccount] = useState(null)
    return (
        <Card>
            <CardHeader>
                <img id="argyle-logo" src="https://argyle.com/images/logos/argyle-logo.svg" />
            </CardHeader>
            <CardBody>
                <CardTitle tag="h5">Argyle Connected Accounts</CardTitle>
                {popoverOpen && selectedAccount &&
                    <ArgyleAccountPopOver
                        isOpen={popoverOpen}
                        toggle={() => setPopoverOpen("")}
                        target={selectedAccount && selectedAccount.accountId ? "accountID" + selectedAccount.accountId.replace("-", "") : "argyle-integration"}
                        employer={selectedAccount ? selectedAccount.employers.join().toUpperCase() : ""}
                        status={selectedAccount ? selectedAccount.connectionStatus : ""}
                        statusCode={selectedAccount ? selectedAccount.connectionCode : ""}
                    />
                }
                {props.allArgyleAccounts && props.allArgyleAccounts.map((account, index) => {
                    return (
                        <Button
                            className={`${account.connectionStatus != "connected" ? "btn-danger" : "success"}` + " mb-2"}
                            id={account.accountId ? "accountID" + account.accountId.replace("-", "") : ""}
                            onClick={() => { setSelectedAccount(account); setPopoverOpen(popoverOpen ? "" : account.source) }}
                        >
                            {account.employers && account.employers.length ? account.employers.join().toUpperCase() : ""}
                        </Button>
                    )
                })}
                {!(props.allArgyleAccounts && props.allArgyleAccounts.length && !props.loading) && <span>No connected accounts</span>}
            </CardBody>
        </Card>
    )
}

const CreatePalencaUserAndSendInvitation = gql`
    mutation createPalencaUserAndSendInvitation($input: CreatePalencaUserAndSendInvitationMutationInput!){
        createPalencaUserAndSendInvitation(input: $input){
            ok
            errors{
                messages
            }
        }
    }
`

const usePalencaInvitation = ({ apolloClient, onSuccess, onError }) => {
    const [isLoading, setIsLoading] = useState(false)

    const sendPalencaInvitation = useCallback(async (driverId) => {
        if (!driverId) return { success: false, error: "Driver ID is required" }
        setIsLoading(true)

        try {
            const response = await apolloClient.mutate({
                mutation: CreatePalencaUserAndSendInvitation,
                variables: {
                    input: {
                        id: driverId
                    }
                }
            })

            const result = response.data?.createPalencaUserAndSendInvitation

            if (!result.ok) {
                throw new Error(result.errors[0].messages[0] ?? "Failed to send Invitation")
            }

            return { success: true }
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : "Failed to send Invitation"
            return { success: false, error: errorMessage }
        } finally {
            setIsLoading(false)
        }
    }, [apolloClient])

    return {
        sendPalencaInvitation,
        isLoading
    }
}


export function PalencaCard(props) {
    const [popoverOpen, setPopoverOpen] = useState("")
    const [selectedAccount, setSelectedAccount] = useState(null)
    const { sendPalencaInvitation, isLoading } = usePalencaInvitation({ apolloClient: props.apolloClient })
    const [palencaResponse, setPalencaResponse] = useState(null)


    const onSendPalencaInvitation = (driverId) => {
        sendPalencaInvitation(driverId).then((res) => {
            setPalencaResponse(res)
        })
    }

    return (
        <Card>
            <CardHeader tag="h4" style={{ color: "#FFF" }} className="d-flex justify-content-between align-items-center">
                Palenca
                <div>
                    <Button style={{ fontSize: "1rem", minWidth: "144px" }} id={"palencaSendInvitation"} onClick={() => { if (!palencaResponse || (palencaResponse && !palencaResponse.success)) onSendPalencaInvitation(props.driver.id) }} >{isLoading ? "Sending" : (palencaResponse && palencaResponse.success) ? "Sent!" : "Send Invitation"}</Button>
                    {palencaResponse && <UncontrolledTooltip
                        target={"palencaSendInvitation"}> {palencaResponse.error ? palencaResponse.error : "Message Sent!"} </UncontrolledTooltip>}
                </div>
            </CardHeader>
            <CardBody>
                <CardTitle tag="h5">Palenca Connected Accounts</CardTitle>
                {popoverOpen && selectedAccount &&
                    <ArgyleAccountPopOver
                        isOpen={popoverOpen}
                        toggle={() => setPopoverOpen("")}
                        target={selectedAccount && selectedAccount.accountId ? "accountID" + selectedAccount.accountId.replace("-", "") : "argyle-integration"}
                        employer={selectedAccount ? selectedAccount.platform.toUpperCase() : ""}
                        status={selectedAccount ? selectedAccount.status : ""}
                        lastSuccessfulConnection={selectedAccount ? selectedAccount.lastSuccessfulConnection : ""}
                        accountId={selectedAccount ? selectedAccount.accountId : ""}
                    />
                }
                {props.allPalencaAccounts && props.allPalencaAccounts.map((account, index) => {
                    return (
                        <Button
                            key={index}
                            className={`${account.status != "success" ? "btn-danger" : "success"}` + " mb-2"}
                            id={account.accountId ? "accountID" + account.accountId.replace("-", "") : ""}
                            onClick={() => { setSelectedAccount(account); setPopoverOpen(popoverOpen ? "" : account.platform) }}
                        >
                            {account.platform && account.platform.length ? account.platform.toUpperCase() : ""}
                        </Button>
                    )
                })}
                {!(props.allPalencaAccounts && props.allPalencaAccounts.length && !props.loading) && <span>No connected accounts</span>}
            </CardBody>
        </Card>
    )
}

function RiskIntegrationDetails(props) {
    if (props.loading || props.branchSettings.loading) return <Loader />
    return (
        <Row>
            <Col xs={12} className="d-flex justify-content-start"><h4>Integrations</h4></Col>
            <Col xs={12}>
                <Row>
                    <Col xs={12} className="argyle-integration" id="argyle-integration">
                        {props.branchSettings.palencaEnabled ? <PalencaCard {...props} /> : <ArgyleCard {...props} />}
                    </Col>
                </Row>
            </Col >
        </Row >
    )
}

//Iagami- Reacct 18 upgradation changes

export default graphql(AllArgyleAccountsQuery, {
    options: ({ argyleUserId }) => ({
        variables: { argyleUserId },
        fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { allArgyleAccounts, loading, variables } }) => ({
        allArgyleAccounts,
        loading,
        variables,
    }),
})(
    graphql(AllPalencaAccountsQuery, {
        options: ({ palencaUserId }) => ({
            variables: { palencaUserId },
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { allPalencaAccounts, loading, variables } }) => ({
            allPalencaAccounts,
            loading,
            variables,
        }),
    })(
        graphql(BranchDetails, {
            options: ({ driver }) => ({
                variables: {
                    id: driver.branch.id,
                },
            }),
            props: ({ data: { branch, loading } }) => {
                let palencaEnabled = false

                if (branch) {
                    palencaEnabled = branch.branchSettings.edges.some((setting) => {
                        const key = setting.node.key.name
                        const value = setting.node.value
                        return key === 'ENABLE_PALENCA_WORKFLOW' && value === 'true'
                    })
                }

                return {
                    branchSettings: {
                        palencaEnabled,
                        loading,
                    },
                }
            },
        })(withApolloClient(RiskIntegrationDetails))
    )
)
