import React, { Component } from 'react'
import gql from 'graphql-tag'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Container, Col, Row, Label, Input, FormGroup, Alert, Button } from 'reactstrap'
import Loader from "../../../Material/Loader"
import { AllPermissions } from "../../Queries"
import "./OverviewTab.css"
//Iagami - Code upgradation React 18
import withApolloClient from '../../../withApolloClient'

const UpdateGroupMutation = gql`
mutation UpdateGroup($input: UpdateGroupMutationInput!){
    updateGroup(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const AllMembers = gql`query AllMembers{
    allMembers {
        edges {
            node {
                id
                user{
                    id
                    username
                    firstName
                    lastName
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

const GroupDetailQuery = gql`query group($id: ID!){
    group(id: $id) {
        id
        name
        permissions {
            edges {
              node {
                id
                name
              }
            }
        }
        userSet {
            edges {
                node {
                    id
                    username
                    firstName
                    lastName
                }
            }
        }
    }
  }  
`

class OverviewTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            loading: false,
            assignedPermissions: [],
            assignedUsers: [],
            permissionSearchTerm: "",
            userSearchTerm: "",
            disableUpdateBtn: true,
            input: {}
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    handleAdd = (sourceId, stateKey) => {
        const selectedElement = document.getElementById(sourceId)
        if (selectedElement) {
            const selectedOptions = Array.from(selectedElement.options).filter((option) => option.selected).map((option) => ({
                value: option.value,
                label: option.text,
            }))
            const updatedState = [...this.state[stateKey], ...selectedOptions]
            const updatedStateKeys = { [stateKey]: updatedState }
            updatedStateKeys["disableUpdateBtn"] = false

            this.setState(updatedStateKeys)
        } else {
            console.error(`Element with ID ${sourceId} does not exist.`)
        }
    };

    handleRemove = (sourceId, stateKey) => {
        const selectedElement = document.getElementById(sourceId)
        if (selectedElement) {
            const selectedOptions = Array.from(selectedElement.options).filter((option) => option.selected).map((option) => option.value)
            const updatedState = this.state[stateKey].filter((item) => !selectedOptions.includes(item.value))
            const updatedStateKeys = { [stateKey]: updatedState }
            updatedStateKeys["disableUpdateBtn"] = false

            this.setState(updatedStateKeys)
        } else {
            console.error(`Element with ID ${sourceId} does not exist.`)
        }
    };

    updateGroup = () => {
        if (this.props.group && this.props.group.id) {
            this.setState({ loading: true })
            let input = this.state.input
            input["groupId"] = this.props.group && this.props.group.id
            if (this.state.assignedPermissions)
                input["permissions"] = this.state.assignedPermissions.map(item => { return item.value })
            if (this.state.assignedUsers)
                input["users"] = this.state.assignedUsers.map(item => { return item.value })
            this.props.apolloClient.mutate({
                mutation: UpdateGroupMutation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateGroup && result.data.updateGroup.ok) {
                    this.props.refetchQuery()
                    this.setState({ input: {}, loading: false, error: null })
                } else if (result.data.updateGroup && result.data.updateGroup.errors) {
                    this.setState({ loading: false, error: String(result.data.updateGroup.errors[0].messages) })
                } else {
                    this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
            })
        } else {
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.group !== prevProps.group) {
            let assignedPermissionsArr = []
            let assignedUsersArr = []
            if (this.props.group && this.props.group.permissions && this.props.group.permissions.edges && this.props.group.permissions.edges.length > 0) {
                this.props.group.permissions.edges.map((item) =>
                    assignedPermissionsArr.push({ value: item.node.id, label: item.node.name })
                )
            }
            if (this.props.group && this.props.group.userSet && this.props.group.userSet.edges && this.props.group.userSet.edges.length > 0) {
                this.props.group.userSet.edges.map((item) =>
                    assignedUsersArr.push({ value: item.node.id, label: item.node.firstName ? item.node.firstName + " " + item.node.lastName : item.node.username })
                )
            }
            this.setState({ assignedPermissions: assignedPermissionsArr, assignedUsers: assignedUsersArr })
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        const assignedPermissionsIds = this.state.assignedPermissions.map(group => group.value)
        const assignedUsersIds = this.state.assignedUsers.map(group => group.value)
        return (
            <Container fluid>
                {this.props.loading || this.state.loading || !this.props.group ? <Loader /> : <>
                    {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                    <Row>
                        <h4>&nbsp;&nbsp;&nbsp;PERMISSION INFO</h4>
                        <Col xs={12} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col>
                                    <Label>Name</Label>
                                    <Input disabled className="box-object-property-input" value={this.props.group.name ? this.props.group.name : "--"} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col>
                                    <Row>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>AVAILABLE PERMISSIONS</h4></b></Label>
                                                <Input className="mb-2" name="permissionSearchTerm" id="permissionSearchTerm" onChange={(e) => this.setState({ permissionSearchTerm: e.target.value })} placeholder="Search Permission" />
                                                <Input
                                                    type="select"
                                                    name="selectedPermissionIds"
                                                    id="selectedPermissionIds"
                                                    multiple
                                                    style={{ height: "300px" }}
                                                >
                                                    {this.props.allPermissions && this.props.allPermissions.edges && this.props.allPermissions.edges.length > 0 && this.props.allPermissions.edges.filter(item => !assignedPermissionsIds.some(groupId => groupId === item.node.id))
                                                        .filter(item => {
                                                            if (this.state.permissionSearchTerm) {
                                                                return item.node.name.toLowerCase().includes(this.state.permissionSearchTerm.toLowerCase())
                                                            } else {
                                                                return true
                                                            }
                                                        })
                                                        .map((item) =>
                                                            <option key={item.node.id} value={item.node.id}>{item.node.name}</option>
                                                        )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2} className="arrow-btn-container">
                                            <Button onClick={() => this.handleAdd("selectedPermissionIds", "assignedPermissions")} className="arrow-btn">
                                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                            </Button>
                                            <br />
                                            <Button onClick={() => this.handleRemove("assignedPermissions", "assignedPermissions")} className="arrow-btn">
                                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                            </Button>
                                        </Col>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>ASSIGNED PERMISSIONS</h4></b></Label>
                                                <Input
                                                    type="select"
                                                    name="assignedPermissions"
                                                    id="assignedPermissions"
                                                    multiple
                                                    style={{ height: "335px" }}
                                                >
                                                    {this.state.assignedPermissions && this.state.assignedPermissions.map((item) =>
                                                        <option key={item && item.value} value={item && item.value}>{item && item.label}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={6} className="bos-object-section-wrapper">
                            <Row className="bos-object-section">
                                <Col>
                                    <Row>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>ALL USERS</h4></b></Label>
                                                <Input className="mb-2" name="userSearchTerm" id="userSearchTerm" onChange={(e) => this.setState({ userSearchTerm: e.target.value })} placeholder="Search By Username" />
                                                <Input
                                                    type="select"
                                                    name="selectedUserIds"
                                                    id="selectedUserIds"
                                                    multiple
                                                    style={{ height: "300px" }}
                                                >
                                                    {this.props.allMembers && this.props.allMembers.edges && this.props.allMembers.edges.length > 0 && this.props.allMembers.edges.filter(item => !assignedUsersIds.some(memberIds => memberIds === item.node.user.id))
                                                        .filter(item => {
                                                            if (this.state.userSearchTerm) {
                                                                return item.node.user.username.toLowerCase().includes(this.state.userSearchTerm.toLowerCase())
                                                            } else {
                                                                return true
                                                            }
                                                        })
                                                        .map((item) =>
                                                            <option key={item.node.user.id} value={item.node.user.id}>{item.node.user.firstName ? item.node.user.firstName + " " + item.node.user.lastName : item.node.user.username}</option>
                                                        )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={2} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                            <Button onClick={() => this.handleAdd("selectedUserIds", "assignedUsers")} className="arrow-btn">
                                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                            </Button>
                                            <br />
                                            <Button onClick={() => this.handleRemove("userIds", "assignedUsers")} className="arrow-btn">
                                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                            </Button>
                                        </Col>
                                        <Col xs={5}>
                                            <FormGroup>
                                                <Label ><b><h4 style={{ color: "#007BFF" }}>ASSIGNED USERS</h4></b></Label>
                                                <Input
                                                    type="select"
                                                    name="userIds"
                                                    id="userIds"
                                                    multiple
                                                    style={{ height: "335px" }}
                                                >
                                                    {this.state.assignedUsers && this.state.assignedUsers.map((item) =>
                                                        <option key={item && item.value} value={item && item.value}>{item && item.label}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-end pr-5"><Button onClick={this.updateGroup} className="overview-primary-btn" disabled={this.state.disableUpdateBtn}>UPDATE</Button></Col>
                    </Row>
                </>}
            </Container>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(GroupDetailQuery, {
    options: ({ groupId }) => ({
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: { id: groupId },
    }),
    props: ({ data: { group, loading, refetch, variables } }) => ({
        group,
        loading,
        variables,
        refetchQuery: () => {
            return refetch({
                query: GroupDetailQuery,
                variables: {
                    ...variables,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    return { group: fetchMoreResult.group }
                },
            })
        },
    }),
})(
    graphql(AllPermissions, {
        props: ({ data: { allPermissions, loading } }) => ({
            allPermissions,
            allPermissionsLoading: loading,
        }),
    })(
        graphql(AllMembers, {
            props: ({ data: { allMembers, loading } }) => ({
                allMembers,
                allMembersLoading: loading,
            }),
        })(
            withApolloClient(OverviewTab)
        )
    )
);

