import React, { Component } from 'react'
import { Container, } from 'reactstrap'
import BranchList from './BranchList'
import './BranchListContainer.css'
import BranchListHeader from "./BranchListHeader"
import RestrictedSection from "../../Components/RestrictedSection"

class BranchListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: null,
            stateCode: "",
            city: "",
            showLoading: false,
            openModal: "",
            refreshRequired: false,
            orderBy: ["name"],
            activeTab: "branches",
            filterValues: {
                isActive: true
            }
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value === "null") {
            value = null
        }
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type) {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }

        this.setState({ [name]: value })
    };

    updateOrder = (string) => {
        let order = [...this.state.orderBy]
        let index = order.indexOf(string)
        if (index > -1) {
            order[index] = "-" + string
        } else {
            index = order.indexOf("-" + string)
            index > -1 ? order.splice(index, 1) : order.unshift(string)
        }
        this.setState({ orderBy: order })
    };

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    render() {
        return (
            <RestrictedSection requiredPermission="view_branch">
                <Container fluid className="bos-listing-container" >
                    <BranchListHeader
                        activeTab={this.state.activeTab}
                        filterValues={this.state.filterValues}
                        setFilterValues={(filterValues) => this.setState({ filterValues })}
                        refreshRequired={this.state.refreshRequired}
                        currentUser={this.props.currentUser}
                        history={this.props.navigate} location={this.props.location}
                        defaultFilters={this.state.filterValues}
                        refetchQuery={() => this.setState({ refetchInsurances: !this.state.refetchInsurances })}
                    />
                    <BranchList
                        currentUser={this.props.currentUser}
                        searchTerm={this.state.filterValues ? this.state.filterValues.searchTerm : null}
                        stateCode={this.state.filterValues ? this.state.filterValues.stateCode : null}
                        tenant={this.state.filterValues ? this.state.filterValues.tenant : null}
                        isActive={this.state.filterValues && this.state.filterValues.isActive !== null ? this.state.filterValues.isActive : null}
                        city={this.state.filterValues ? this.state.filterValues.city : null}
                        orderBy={this.state.orderBy}
                        updateOrder={this.updateOrder}

                    />
                </Container>
            </RestrictedSection>
        )
    }
}


//Iagami - Code upgradation React 18
export default BranchListContainer

