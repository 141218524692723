import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Updated Apollo HOC import
import gql from 'graphql-tag'
import { Row, Col, Label, Alert, Button, FormGroup, FormText, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import './AddNewDriver.css'
import Select from 'react-select'
import Languages from "../../Material/Language.json"
import { EMAIL_REGEX } from "../../Constants"
import { validatePhone, getPhonePlaceholder, getPhoneMaxLength } from "../../utils"
import withApolloClient from '../../withApolloClient' // Iagami - Code upgradation React 18

const CreateLeadMutation = gql`mutation CreateLeadMutation($input: CreateLeadMutationInput!){
    createCustomerLead(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const AllBranches = gql`query AllBranches{
    allBranches{
        edges{
            node{
                id
                customId
                name
                country
            }
        }
    }
}`
const referralOptions = ["Google", "Instagram", "Youtube", "Facebook", "Uber", "Friend"]
class AddNewDriver extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fullName: "",
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            referredBy: "",
            dob: "",
            preferredLanguage: "",
            selectedBranch: "",
            branchCountry: "",
            errorMessage: "",
            successMessage: "",
            documents: [],
            loading: false,
            isValidPhone: false,
            isValidEmail: false,
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }
    createNewDriver = (e) => {
        try {
            if (this.state.selectedBranch && this.state.phone && this.state.fullName && this.state.phone && this.state.email && this.state.referredBy && this.state.preferredLanguage) {
                this.setState({ loading: true })
                let input = {
                    fullName: this.state.fullName,
                    phone: this.state.phone,
                    email: this.state.email,
                    referredBy: this.state.referredBy,
                    preferredLanguage: this.state.preferredLanguage,
                    isTlc: ["BROOKLYN_NY", "FASTTRACK_BROOKLYN"].includes(this.state.selectedBranch) ? true : false,
                    branchCustomId: this.state.selectedBranch,
                    source: "BOS",
                }
                if (this.state.documents && this.state.documents.length > 0) {
                    input["documents"] = this.state.documents
                }
                this.props.apolloClient.mutate({
                    mutation: CreateLeadMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.createCustomerLead && result.data.createCustomerLead.ok) {
                        this.setState({ errorMessage: "", successMessage: "Succesfully Created Customer Lead" })
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (result.data && result.data.createCustomerLead && result.data.createCustomerLead.errors && result.data.createCustomerLead.errors[0] && result.data.createCustomerLead.errors[0].messages) {
                            errorMessage = result.data.createCustomerLead.errors[0].messages.toString()
                        }
                        this.setState({ errorMessage: errorMessage })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let errorMessage = "An error has occured"
                    this.setState({ errorMessage: errorMessage, loading: false })
                })
            }
            else this.setState({ errorMessage: "Missing Required Field" })
        }
        catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ errorMessage: errorMessage, loading: false })
        }
    }

    handleBranchChange = (selectedBranch) => {
        const allBranches = this.props.allBranches
        let branchCountry = ""

        if (allBranches && allBranches.edges && allBranches.edges.length > 0) {
            const selectedBranchNode = allBranches.edges.find(
                (branch) => branch.node.customId === selectedBranch
            )
            if (selectedBranchNode && selectedBranchNode.node) {
                branchCountry = selectedBranchNode.node.country
            }
        }

        this.setState({ branchCountry, selectedBranch }, () => {
            if (this.state.phone) {
                const { isValid, errorMessage } = validatePhone(this.state.phone, branchCountry)
                this.setState({ isValidPhone: isValid, errorMessage })
            }
        })
    };

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value === "null") {
            value = null
        }
        if (name === "email") {
            if (EMAIL_REGEX.test(value))
                this.setState({ errorMessage: null, isValidEmail: true })
            else
                this.setState({ errorMessage: "Please enter a valid email address.", isValidEmail: false })
        }
        if (name === "phone") {
            const { branchCountry } = this.state
            const { isValid, errorMessage } = validatePhone(value, branchCountry)
            this.setState({ isValidPhone: isValid, errorMessage })
        }
        if (e.target.dataset.type) {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }

        this.setState({ [name]: value })
    };

    getLanguageVal(langCode) {
        if (Languages.hasOwnProperty(langCode)) {
            return `${Languages[langCode].name} - ${Languages[langCode].nativeName}`
        } else {
            return null
        }
    }

    updateFile = (e) => {
        let _this = this
        let base64Img = e.target.files[0]
        let filename = e.target.name
        let documents = [...this.state.documents]
        let newDocument = {
            documentType: filename,
            base64String: null
        }
        var reader = new FileReader()
        reader.readAsDataURL(base64Img)
        reader.onload = function (e) {
            let imageInBase64 = reader.result
            imageInBase64 = imageInBase64.substring(imageInBase64.indexOf(',') + 1)
            newDocument.base64String = imageInBase64
            documents.push(newDocument)
            _this.setState({ documents: documents, error: null })
        }
    }

    render() {
        return (
            <div>
                <Modal className="Add-driver-modal" isOpen={this.props.open}>
                    <ModalHeader>Create Customer</ModalHeader>
                    <ModalBody>
                        {this.state.errorMessage &&
                            <Col xs={12}>
                                <Alert color="danger">
                                    {this.state.errorMessage}
                                </Alert>
                            </Col>
                        }
                        {this.state.successMessage &&
                            <Col xs={12}>
                                <Alert color="success">
                                    {this.state.successMessage}
                                </Alert>
                            </Col>
                        }
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="selectedBranch">Select Branch*</Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={!(this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0)}
                                        options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map(branch => ({ value: branch.node.customId, label: branch.node.name }))}
                                        placeholder="Select Branch"
                                        onChange={(branch) => this.handleBranchChange(branch.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="fullName">Full Name*</Label>
                                    <Input name="fullName" id="fullName" onChange={this.updateInput} placeholder="Full Name" invalid={!this.state.fullName} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="phone">Phone*</Label>
                                    <Input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        onChange={this.updateInput}
                                        placeholder={getPhonePlaceholder(this.state.branchCountry)}
                                        invalid={!this.state.isValidPhone}
                                        maxLength={getPhoneMaxLength(this.state.branchCountry)}
                                        disabled={!this.state.selectedBranch}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="email">Email*</Label>
                                    <Input type="email" name="email" id="email" onChange={this.updateInput} placeholder="e.g. johndoe@example.com" invalid={!this.state.email} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="preferredLanguage">Preferred Language*</Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        options={Object.keys(Languages).map((key, i) => ({ value: key, label: Languages[key].name + " - " + Languages[key].nativeName }))}
                                        placeholder="Choose Preferred Language"
                                        onChange={(language) => this.setState({ preferredLanguage: language.value })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="preferredLanguage">Referred By*</Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        options={referralOptions.map((item, i) => ({ value: item, label: item }))}
                                        placeholder="Please choose one"
                                        onChange={(ref) => this.setState({ referredBy: ref.value })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="Driver License">Upload Driver License (Optional)</Label>
                                    <Input type="file" name="Driver License" id="Driver License" onChange={this.updateFile} accept=".jpg,.jpeg,.png" />
                                    <FormText color="muted">Accepted files include jpg,jpeg and pngs</FormText>
                                </FormGroup>
                            </Col>
                            {(this.state.selectedBranch === "BROOKLYN_NY" || this.state.selectedBranch === "FASTTRACK_BROOKLYN") &&
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="TLC">Upload TLC License (Optional)</Label>
                                        <Input type="file" name="TLC" id="TLC" onChange={this.updateFile} accept=".jpg,.jpeg,.png" />
                                        <FormText color="muted">Accepted files include jpg,jpeg and pngs</FormText>
                                    </FormGroup>
                                </Col>}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline className="Add-driver-modal-secondary-btn" onClick={this.props.handleClose}>Close</Button>
                        <Button className="Add-driver-modal-primary-btn" onClick={this.createNewDriver} disabled={this.state.loading || this.state.successMessage || !(this.state.phone || this.state.email || this.state.fullName || this.state.referredBy || this.state.preferredLanguage || !this.state.isValidEmail || !this.state.isValidPhone)}>{this.state.loading ? "Loading..." : "Add New Customer"}</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default (
    graphql(AllBranches, {
        options: { fetchPolicy: 'cache-and-network' },
        props: ({ data: { loading, allBranches } }) => { return { loading, allBranches } }
    })
)(withApolloClient(AddNewDriver))
