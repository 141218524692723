import React, { Component, Fragment } from "react"
import { Button, ButtonGroup, Modal, ModalBody, Progress, Popover, PopoverHeader, PopoverBody, UncontrolledTooltip, Label, Row, Col } from 'reactstrap'
import DatetimeRenderer from "../DatetimeRenderer"
import "./DocumentListNew.css"
import UpdateDocumentModal from "./UpdateDocumentModal"
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import moment from "moment"
import { isImage } from "./DocumentsListNew"
import { HasPermissionsQuery } from "../../Functions/querys"
import { ReactComponent as DocVerified } from "../../assets/images/icons/doc-verified.svg"
import ConfirmationPopup from "../ConfirmationPopup"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

const UpdateDocumentMutation = gql`mutation updateDocumentMutation($input: UpdateDocumentMutationInput!){
    updateDocument(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const DeleteDriverDocumentMutation = gql`mutation deleteDriverDocumentMutation($input: DeleteDriverDocumentMutationInput!){
    deleteDriverDocument(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const ExecuteOCR = gql`mutation ExecuteOCR($input: ExecuteOCRInput!){
    executeOcr(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const HISTORY_TABLE_HEADER = [
    { id: "date", name: "Date" },
    { id: "detail", name: "Details" },
    { id: "createdBy", name: "Created By" },
]

class DocumentModalNew extends Component {
    constructor() {
        super()
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.state = {
            modal: true,
            openModal: "",
            rotation: 0,
            scale: 1,
            ocrSuccessMessage: "",
            ocrError: "",
            loading: false,
            error: "",
            operationType: "",
            documentUpdated: false
        }
    }

    handleClose = (e) => {
        let state = {
            loading: false,
            error: null,
            documentUpdated: false,
            openModal: ""
        }
        this.setState({ ...state })
        this.props.handleClose && this.props.handleClose()
        this.props.refetch && this.props.refetch()
    };


    printDoc = (url) => {
        var printWindow = window.open(url, "Print", "width=800,height=600")
        printWindow.print()
    }

    downloadDoc = (url) => {
        // TODO: Add download functionality
        window.open(url, "_blank")
    }

    rotateDoc = () => {
        let rotation = this.state.rotation + 90
        rotation = rotation >= 360 ? 0 : rotation
        rotation = rotation < 0 ? 0 : rotation
        this.setState({ rotation: rotation })
    }

    scaleDoc = (addScale) => {
        let scale = this.state.scale + addScale
        scale = scale < 0 ? 0.1 : scale
        this.setState({ scale: scale })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.document !== this.props.document) {
            this.setState({ rotation: 0, scale: 1 })
        }
    }

    handleClose = (e) => {
        let state = {
            loading: false,
            error: null,
            documentUpdated: false,
            openModal: ""
        }
        this.setState({ ...state })
        this.props.handleClose && this.props.handleClose()
        this.props.refetch && this.props.refetch()
    };

    handleExecuteOcr = (id, forceVerify) => {
        if (id) {
            try {
                this.setState({ loading: true, ocrError: "", ocrSuccessMessage: "" })
                let input = {
                    documentId: id,
                    forceVerify: forceVerify
                }
                this.props.apolloClient.mutate({
                    mutation: ExecuteOCR,
                    variables: { input },
                }).then((result) => {
                    if (result.data && result.data.executeOcr && result.data.executeOcr.ok) {
                        this.setState({ loading: false, ocrSuccessMessage: "OCR process completed successfully. Please refresh the page to view the changes." })
                    } else if (result.data && result.data.executeOcr && result.data.executeOcr.errors && result.data.executeOcr.errors[0].messages[0]) {
                        this.setState({ loading: false, ocrError: result.data.executeOcr.errors[0].messages[0] })
                    }
                }).catch((error) => {
                    let errorMessage = "An error has occurred: " + error
                    this.setState({ ocrError: errorMessage, loading: false })
                })
            } catch (err) {
                let errorMessage = "An error has occurred: " + err
                this.setState({ ocrError: errorMessage, loading: false })
            }
        } else {
            let errorMessage = "Missing Required Field"
            this.setState({ ocrError: errorMessage, loading: false })
        }
    };

    documentHandler = (id, action) => {
        try {
            this.setState({ loading: true })
            let input = {}
            if (action === "delete") {
                input["documentId"] = id
            }
            if (["archive", "unarchive"].includes(action)) {
                input["id"] = id
                if (action === "archive") {
                    input["isArchived"] = true
                } else if (action === "unarchive") {
                    input["isArchived"] = false
                }
            }
            this.props.apolloClient.mutate({
                mutation: ["archive", "unarchive"].includes(action) ? UpdateDocumentMutation : DeleteDriverDocumentMutation,
                variables: { input },
            }).then((result) => {
                if (["archive", "unarchive"].includes(action) && result.data && result.data.updateDocument && result.data.updateDocument.ok) {
                    this.handleClose()
                } else if (action === "delete" && result.data && result.data.deleteDriverDocument && result.data.deleteDriverDocument.ok) {
                    this.handleClose()
                } else if (["archive", "unarchive"].includes(action) && result.data && result.data.updateDocument && result.data.updateDocument.errors[0].messages[0]) {
                    this.setState({ loading: false, documentUpdated: false, error: result.data.updateDocument.errors[0].messages[0] })
                } else if (action === "delete" && result.data && result.data.deleteDriverDocument && result.data.deleteDriverDocument.errors[0].messages[0]) {
                    this.setState({ loading: false, documentUpdated: false, error: result.data.deleteDriverDocument.errors[0].messages[0] })
                }
                else {
                    this.setState({ loading: false, documentUpdated: false, error: "An error has occured. Please contact system admin" })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, documentUpdated: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, documentUpdated: false, })
        }
    };

    componentWillMount() {
        document.addEventListener('keydown', this.handleKeyPress)
    }

    handleKeyPress(event) {
        //  If key is next arrow key and modal is open, call next function
        if (event.keyCode === 39 && this.state.modal) {
            this.next()
        }
        //  If key is previous arrow key and modal is open, call previous function
        if (event.keyCode === 37 && this.state.modal) {
            this.prev()
        }
    }

    next = () => {
        this.props.next && this.props.next()
    }

    prev = () => {
        this.props.prev && this.props.prev()
    }

    fetchMetaData = (metadata = null, name) => {
        if (!metadata || metadata === "")
            return
        try {
            metadata = JSON.parse(metadata.replace(/'/g, '"').replace(/True/g, 'true').replace(/False/g, 'false'))
        } catch {
            metadata = null
        }
        return metadata && metadata[name] ? metadata[name] : null
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        const { document } = this.props
        return (
            <Modal
                className="DocViewerModal"
                isOpen={this.state.modal}
                toggle={() => this.setState({ modal: !this.state.modal })}
                onClosed={() => this.props.handleClose && this.props.handleClose()}
                fullscreen
            >
                {!(document && document.documentUrl) ?
                    <div>No doc passed</div> :
                    <>
                        {["delete", "archive", "unarchive"].includes(this.state.operationType) &&
                            <ConfirmationPopup
                                open={["delete", "archive", "unarchive"].includes(this.state.operationType)}
                                message={"Are you sure you want to " + this.state.operationType + " this document?"}
                                loading={this.state.loading}
                                handleClose={() => this.setState({ operationType: "" })}
                                action={"Yes"}
                                confirmAction={() => this.documentHandler(document.id, this.state.operationType)}
                                color="danger"
                                error={this.state.error ? this.state.error : ""}
                                success={this.state.documentUpdated ? "Document " + this.state.operationType + "d successfully" : ""}
                            />
                        }
                        {this.state.openModal === "UpdateDocumentModal" &&
                            <UpdateDocumentModal
                                currentUser={this.props.currentUser}
                                selectedDocId={document.id}
                                handleClose={() => { this.setState({ openModal: "" }); this.props.handleClose && this.props.handleClose() }}
                                open={this.state.openModal === "UpdateDocumentModal"}
                                target="UpdateDocumentButton"
                                objectType={this.props.objectType}
                                refetchQuery={this.props.refetch}
                                driverId={this.props.driverId}
                            />
                        }

                        {this.state.openModal === "DocumentDetailsPopover" &&
                            <Popover placement="top" isOpen={this.state.openModal === "DocumentDetailsPopover"} target={"DocumentDetailsPopover"} handleClose={() => { this.setState({ openModal: "" }) }}>
                                <PopoverHeader onClick={() => { this.setState({ openModal: "" }) }}>
                                    Details
                                    <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"></i>
                                </PopoverHeader>
                                <PopoverBody>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ flex: 1 }}>
                                            <p>Date Added:</p>
                                            {this.props.isPicture ? <p>Description</p> : <><p>Title</p><p>Expiration Date:</p></>}
                                        </div>
                                        <div style={{ flex: 1, textAlign: "right" }}>
                                            <p>{document.dateAdded ? moment(document.dateAdded).format('lll') : '--'}</p>
                                            {this.props.isPicture ? <p>{document.description}</p> : <><p>{document.name}</p><p>{document.expiration ? moment(document.expiration).format(moment.HTML5_FMT.DATE) : '--'}</p></>}
                                        </div>
                                    </div>
                                    <Button type="button" size="sm" onClick={() => { this.setState({ openModal: "" }) }}>Close</Button>
                                </PopoverBody>
                            </Popover>
                        }
                        {this.state.openModal === "documentHistory" &&
                            <Popover placement="bottom" isOpen={this.state.openModal} target={"documentHistory"} toggle={() => this.setState({ openModal: false })}>
                                <PopoverHeader className="text-center">Document History</PopoverHeader>
                                <PopoverBody>
                                    <Row>
                                        {HISTORY_TABLE_HEADER.map(tableHeader =>
                                            <Col key={tableHeader.id} xs={4}><Label >{tableHeader.name}</Label></Col>
                                        )}
                                    </Row>
                                    {document.history && document.history.length > 0 ? document.history.map(history => <>
                                        <Row>
                                            <Col><p>{history.date ? <DatetimeRenderer datetime={history.date} /> : "--"}</p></Col>
                                            <Col><p>{history.comment ? history.comment : "--"}</p></Col>
                                            <Col><p>{history.user ? history.user : "--"}</p></Col>
                                        </Row>
                                        <hr />
                                    </>) :
                                        <Row>
                                            <Col>
                                                <p>No Document History Found!</p>
                                            </Col>
                                        </Row>
                                    }
                                </PopoverBody>
                            </Popover>
                        }
                        <div className="DocViewerModalHeader">
                            {/* Add document title on the left and 3 action buttons on the right */}
                            <p className="DocViewerModalTitle">
                                {document.documentType && document.documentType.typeName ? document.documentType.typeName : (document.name || 'Document Name')} &nbsp;
                                {/* Add verified icon if verified */}
                                {document.isVerified && <>
                                    <UncontrolledTooltip target="verified">{"Verified By " + (this.fetchMetaData(document.metadata, "verified_by") || "System")}</UncontrolledTooltip>
                                    {this.fetchMetaData(document.metadata, "verified_by") != "System" ? <DocVerified id="verified" width="20" height="20" className="fa fa-check-circle" fill="green" /> : <i id="verified" className="fa fa-check-circle" style={{ color: "green" }}></i>}
                                </>}
                            </p>

                            <div className="DocViewerModalActions">

                                <p onClick={() => this.rotateDoc()}><i className="fa fa-rotate-right"></i></p>
                                <p onClick={() => this.scaleDoc(0.1)}><i className="fa fa-search-plus"></i></p>
                                <p onClick={() => this.scaleDoc(-0.1)}><i className="fa fa-search-minus"></i></p>
                                <p onClick={() => this.downloadDoc(document.documentUrl)}><i className="fa fa-download"></i></p>
                                <p onClick={() => this.printDoc(document.documentUrl)}><i className="fa fa-print"></i></p>
                                <p><i className="fa fa-times" onClick={() => { this.setState({ modal: !this.state.modal }); this.props.handleClose && this.props.handleClose() }}></i></p>
                            </div>
                        </div>
                        <ModalBody>
                            {this.state.loading && <Progress animated color="info" value="100">Loading...</Progress>}
                            {this.state.ocrError && <div className="alert alert-danger">{this.state.ocrError}</div>}
                            {this.state.ocrSuccessMessage && <div className="alert alert-success">{this.state.ocrSuccessMessage}</div>}
                            {
                                document ? (
                                    isImage(document.documentUrl, document.name) ?
                                        <img style={{ transform: `rotate(${this.state.rotation}deg) scale(${this.state.scale})` }} className="DocViewerImage" src={document.documentUrl} alt="Document" /> :
                                        <iframe scrolling="yes" className="DocViewerFrame" src={document.documentUrl} />
                                ) : ''
                            }
                        </ModalBody>

                        <div className="DocViewerModalFooter">
                            {/* Add previous and next buttons on the left and close button on the right */}
                            <ButtonGroup>
                                <Button className="btn btn-dark" onClick={() => this.prev()}><i className="fa fa-chevron-left"></i> Prev</Button>
                                <Button className="btn btn-dark" onClick={() => this.next()}>Next <i className="fa fa-chevron-right"></i></Button>
                            </ButtonGroup>

                            <div className="DocViewerFooterAction">
                                <Button className="btn btn-secondary" id="DocumentDetailsPopover" onClick={() => this.setState({ openModal: "DocumentDetailsPopover" })}><i className="fa fa-info-circle"></i> Details</Button>
                                <Button className="btn btn-secondary" style={{ background: "#008B8B" }} id="documentHistory" onClick={() => this.setState({ openModal: "documentHistory" })}><i className="fa fa-history"></i> History</Button>
                                {!this.props.isPicture &&
                                    <>
                                        {document.isArchived && <><Button className="btn btn-danger" onClick={() => this.setState({ operationType: "unarchive" })}><i className="fa fa-file-archive-o" aria-hidden="true"></i>&nbsp;Unarchive</Button></>}
                                        {document && !document.isArchived && <Button className="btn btn-danger" onClick={() => this.setState({ operationType: "archive" })}><i className="fa fa-trash"></i>&nbsp;Archive</Button>}
                                        {document && document.isArchived && this.props.hasPermissions && this.props.hasPermissions.includes("delete_driverdocument") && this.props.objectType === "driver" && <Button className="btn btn-danger" onClick={() => this.setState({ operationType: "delete" })}><i className="fa fa-trash"></i>&nbsp;Delete</Button>}
                                        <Button className="btn btn-warning" id="UpdateDocumentButton" onClick={() => this.setState({ openModal: "UpdateDocumentModal" })}><i className="fa fa-pencil-square"></i>&nbsp;Edit</Button>
                                    </>}
                                {["TLC", "Driver License", "Constancia de Situacion Fiscal"].includes(document.documentType && document.documentType.typeName ? document.documentType.typeName : "") &&
                                    <Fragment>
                                        <Button className="btn btn-info" onClick={() => this.handleExecuteOcr(document.id, false)}><i className="fa fa-id-card"></i> Doc Validation</Button>
                                        {this.props.hasPermissions && this.props.hasPermissions.includes("custom_force_run_ocr") &&
                                            <Button className="btn btn-info" onClick={() => this.handleExecuteOcr(document.id, true)}><i className="fa fa-id-card"></i> Manual Doc Validation </Button>}

                                    </Fragment>
                                }
                                <Button className="btn btn-dark" onClick={() => { this.setState({ modal: !this.state.modal }); this.props.handleClose && this.props.handleClose() }}><i className="fa fa-times"></i> Close</Button>
                            </div>
                        </div>

                    </>
                }
            </Modal>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(HasPermissionsQuery, {
    options: () => ({ variables: { userPermissions: ["custom_force_run_ocr", "delete_driverdocument"] } }),
    props: ({ data: { hasPermissions, loading, variables } }) => (
        { hasPermissions, loading, variables }
    )
})
    (withApolloClient(DocumentModalNew))
