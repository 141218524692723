import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Col, Row, Input } from 'reactstrap'
import ListingFilters from "../../Material/ListingFilters"
import { debounce } from 'lodash'
import toTitleCase from "../../Functions/toTitleCase"
import CreateUpdateMemberModal from "./CreateUpdateMemberModal"
import UpdateMembers from "./UpdateMembers"
import { HasPermissionsQuery } from "../../Functions/querys"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

const AllBranches = gql`query AllBranches{
    optionsList: allBranches{
        edges{
            node{
                id
                name
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    } 
}`

const AllGroups = gql`query AllGroups{
    optionsList: allGroups {
        edges {
            node {
                id
                name
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

const AllTenants = gql`query AllTenants($cursor: String, $first:Int,){
    optionsList: allTenants(first:$first, after: $cursor){
        edges {
            node {
                id
                name
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllPermissions = gql`query AllPermissions($cursor: String, $first:Int,){
    optionsList: allPermissions(first:$first, after: $cursor){
        edges {
            node {
                id
                name
                contentType{
                    model
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

class MembersListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null
        }
    }
    getFilterConfigurations = (activeTab) => {
        return [
            { type: "select", name: "branchId", title: "Filter By Branch", optionsQuery: AllBranches, placeholder: "Filter By Branch", valueSelector: "id", labelSelector: "name", showFilter: true, isMulti: true },
            { type: "select", name: "groups", title: "Filter by Group", optionsQuery: AllGroups, placeholder: "Filter by Group", valueSelector: "id", labelSelector: "name", showFilter: true, isMulti: true },
            { type: "select", name: "tenant", title: "Filter by Tenant", optionsQuery: AllTenants, placeholder: "Filter by Tenant", valueSelector: "id", labelSelector: "name", showFilter: true },
            { type: "select", name: "permissions", title: "Filter by Permissions", optionsQuery: AllPermissions, placeholder: "Filter by Permissions", valueSelector: "id", labelSelector: "name", showFilter: true, isMulti: true },
            { type: "boolean", name: "isActive", title: "Active User", optionOne: "YES", optionTwo: "NO", showFilter: true },
            { type: "boolean", name: "isSuperuser", title: "Superuser", optionOne: "YES", optionTwo: "NO", showFilter: true },
        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = (searchTerm) => {
        this.props.setFilterValues({ ...this.props.filterValues, searchTerm })
    }
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            this.props.setFilterValues({ ...this.props.filterValues })
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean")
            value = toTitleCase(value.toString())
        return Array.isArray(value) ? value.join(", ") : value
    }
    componentDidMount() {
        let conf = this.getFilterConfigurations(this.props.activeTab)
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.apolloClient.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0)
                        options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }))
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
        const { apolloClient } = this.props;
    }
    render() {
        return (
            <Row className="pb-3">
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={this.props.setFilterValues}
                        filters={this.state.configurations}
                    />}

                <Col xs={4}>
                    <span className="driver-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                {this.state.openModal === ("AssignGroup") && <UpdateMembers handleClose={() => this.toggleModal("")} isOpen={this.state.openModal === ("AssignGroup")} refetchQuery={() => this.props.refetchQuery()} memberDetails={this.props.selectedMembers} />}
                {this.state.openModal === ("BulkMemberBrnachUpdate") && <CreateUpdateMemberModal handleClose={() => this.toggleModal("")} isOpen={this.state.openModal === ("BulkMemberBrnachUpdate")} refetchQuery={() => this.props.refetchQuery()} memberDetails={this.props.selectedMembers} action={""} isMultiUpdate={true} isUpdate branchId={""} />}
                {this.state.openModal === ("AddMember") && <CreateUpdateMemberModal handleClose={() => this.toggleModal("")} isOpen={this.state.openModal === ("AddMember")} refetchQuery={() => this.props.refetchQuery()} memberDetails={""} action={""} isMultiUpdate={false} branchId={""} />}
                <Col xs={8} className="text-right mt-2">
                    {this.props.selectedMembers && this.props.selectedMembers.length > 0 && this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_member_sensitive_info") &&
                        <>
                            <a className="driver-list-button" onClick={() => this.toggleModal("AssignGroup")}>
                                Assign Group | <i className="fa fa-plus" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                    }
                    {this.props.selectedMembers && this.props.selectedMembers.length > 0 && this.props.hasPermissions && this.props.hasPermissions.includes("change_member") ?
                        <>
                            <a className="driver-list-button" onClick={() => this.toggleModal(("BulkMemberBrnachUpdate"))}>
                                Update Selected Members | <i className="fa fa-edit" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        </> :
                        this.props.showView === "membersList" && <>
                            <a className="driver-list-button" onClick={() => this.toggleModal("AddMember")}>
                                Add Member | <i className="fa fa-plus" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                    }
                    {this.props.showView === "membersList" && this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_member_sensitive_info") &&
                        <a className="driver-list-button" onClick={() => this.props.changeView("createUserBtn")}>
                            Create User | <i className="fa fa-user-plus" aria-hidden="true"></i>
                        </a>
                    }
                    {this.props.showView != "membersList" &&
                        <a className="driver-list-button" onClick={() => this.props.changeView("membersList")}>
                            Members List | <i className="fa fa-users" aria-hidden="true"></i>
                        </a>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {this.props.showView === "membersList" && <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>}
                </Col>
                <Col xs={12} className="mt-2">
                    {this.props.showView === "membersList" && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedMembers && this.props.selectedMembers.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedMembers}></i> &nbsp;&nbsp; Selected Members</span>
                        {this.props.selectedMembers.length}
                    </span>}
                </Col>
            </Row>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(HasPermissionsQuery, {
    options: () => ({
        variables: { userPermissions: ["custom_can_edit_member_sensitive_info", "change_member"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true
    }),
    props:({ data: { hasPermissions, loading, variables } }) =>({
        hasPermissions, loading, variables 
    })
})(withApolloClient(MembersListHeader));
