import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import '../BranchMangement/BranchListContainer.css'
import { Container } from 'reactstrap'
import AllMembersList from "./AllMembersList"
import CreateUser from "./CreateUser"
import MembersListHeader from "./MemberListHeader"
import RestrictedSection from "../../Components/RestrictedSection"
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

class MembersListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: null,
            showEdit: "",
            input: {},
            searchTerm: "",
            openModal: "",
            selectedMembers: [],
            showView: "membersList",
            refetchMembers: false,
            filterValues: {
                searchTerm: null,
                isActive: true,
            }
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        let currentState = this.state
        currentState[name] = value
        this.setState({ ...currentState })
    }


    render() {
        return (
            <RestrictedSection requiredPermission="view_member">
                <Container fluid className="bos-listing-container">
                    <MembersListHeader
                        activeTab="members"
                        currentUser={this.props.currentUser}
                        filterValues={this.state.filterValues}
                        setFilterValues={(filterValues) => this.setState({ filterValues })}
                        location={this.props.location} history={this.props.navigate}
                        defaultFilters={this.state.filterValues}
                        showView={this.state.showView}
                        changeView={(showView) => this.setState({ showView })}
                        selectedMembers={this.state.selectedMembers}
                        resetSelectedMembers={() => this.setState({ selectedMembers: [] })}
                        refetchQuery={() => this.setState({ refetchMembers: !this.state.refetchMembers })}
                    />
                    {this.state.showView === "membersList" ?
                        <AllMembersList
                            key={String(this.state.refetchMembers)}
                            searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                            branchIds={this.state.filterValues && this.state.filterValues.branchId ? this.state.filterValues.branchId : null}
                            groups={this.state.filterValues && this.state.filterValues.groups ? this.state.filterValues.groups : null}
                            tenant={this.state.filterValues && this.state.filterValues.tenant ? this.state.filterValues.tenant : null}
                            permissions={this.state.filterValues && this.state.filterValues.permissions ? this.state.filterValues.permissions : null}
                            isSuperuser={this.state.filterValues && this.state.filterValues.isSuperuser !== null ? this.state.filterValues.isSuperuser : null}
                            selectedMembers={this.state.selectedMembers}
                            setSelectedMembers={(selectedMembers) => this.setState({ selectedMembers })}
                            isActive={this.state.filterValues && this.state.filterValues.isActive !== null ? this.state.filterValues.isActive : null}
                        />
                        : <CreateUser memberDetails={""} />}
                </Container>
            </RestrictedSection>
        )
    }
}
//Iagami - Code upgradation React 18
export default (withApolloClient(MembersListContainer));